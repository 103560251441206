import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const getVerificationList=(callBack) =>{
    let path=ApiUrl+"verifications/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const getVerificationPictures=(callBack, pk=false) =>{
    let path=ApiUrl+"verifications/pictures/"
    if(pk){
      path=path+pk+"/"
    }
    console.log(path)
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const sendVerificationNotification=(fd, callBack) =>{
    let path=ApiUrl+"verifications/idpictures/sendnotification/"
    console.log(path)
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const sendVerification=(fd, pk, callBack) =>{
    let path=ApiUrl+"verifications/"+pk+"/"
    console.log(path)
    return dispatch =>{
      axiosClient.patch(path, fd)
      .then( (response)=> {
        dispatch({ type: "SET_PROFILE_DATA", data: response.data.profile});
        dispatch({ type: "SET_ADMIN_LOGS_DATA", data: response.data.adminLogs});
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
  
      });
    }
  }


  export const updateVerificationPics=(id, fd, callBack) =>{
    let path=ApiUrl+"verifications/idpictures/"+id+"/"
    console.log(path)
    return dispatch =>{
      axiosClient.patch(path, fd)
      .then( (response)=> {
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack({error:true, data:error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const changeVerificationPicsStatus=(id, fd, callBack) =>{
    let path=ApiUrl+"verifications/idpictures/"+id+"/"
    console.log(path)
    return dispatch =>{
      axiosClient.patch(path, fd)
      .then( (response)=> {
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
  
      });
    }
  }