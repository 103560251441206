
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    SearchZarobkiByTransId,
} from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import LoaderAbsoluteOverlay from "../components/loaderAbsoluteOverlay";



import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";


import toast, { Toaster } from 'react-hot-toast';




class TransakcjeView extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    state = {
        idTransakcji: ''
    }


    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }

    search = () => {
        this.setState({ fetching: true })
        SearchZarobkiByTransId(this.state.idTransakcji, this.fetched)
    }

    fetched = (data) => {
        console.log('fetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
            this.setState({ data: data })
        }
    }
    showDetails=(item)=>{
        console.log('showDetails', item)
        this.setState({redirect:'/zarobki/details/'+item.id+'/'})
    }
    renderList = () => {
        if(!this.state.data){
            return null
        }
        return (
            this.state.data.map((item, index) => {
                //1- abonament, 2- tip, 3-referral program, 4 - ppv, 5 - snapy
                let rodzaj = ''
                if (item.rodzaj === 1) {
                    rodzaj = 'Sub'
                }
                else if (item.rodzaj === 2) {
                    rodzaj = 'Tip'
                }
                else if (item.rodzaj === 3) {
                    rodzaj = 'Pp'
                }
                else if (item.rodzaj === 4) {

                    if (item.subDetails.containerName) {
                        rodzaj = (
                            <Link to={{ pathname: 'https://www.watchmemore.com/' + item.blogger.urlName + '/ppv/' + item.subDetails.containerName + '/' }} target={'_blank'} >
                                PPV
                            </Link >
                        )
                    }
                    else {
                        rodzaj = 'PPV'
                    }
                }
                else if (item.rodzaj === 5) {
                    rodzaj = 'Sn'
                }
                else {
                    rodzaj = 'Inny'
                }
                let kwota;
                if (item.walutaTransakcji.toLowerCase() === 'pln') {
                    kwota = item.kwotaDoWyplatyPln
                }
                else if (item.walutaTransakcji.toLowerCase() === 'eur') {
                    kwota = item.kwotaDoWyplatyEur
                }
                else {
                    kwota = '????'
                }
                return (
                    <tr key={'kupionetem' + index} className='cursor'>
                        <td className="cursor" onClick={() => this.showDetails(item)}>
                            {item.time}
                        </td>
                        <th scope="row" className="capitalize">
                            <strong>
                                <Link to={{ pathname: 'https://www.watchmemore.com/' + item.blogger.urlName + '/' }} target={'_blank'}>
                                    {item.blogger.displayName}
                                </Link>
                            </strong>
                        </th>
                        <td className="">
                            {rodzaj}

                        </td>
                        {item.isRefund &&
                            <td className="cursor text-danger" onClick={() => this.showDetails(item)}>
                                {kwota}{item.walutaTransakcji}
                            </td>
                        }
                        {item.refund &&
                            <td style={{ textDecoration: "line-through" }} className="cursor text-danger" onClick={() => this.showDetails(item)}>
                                {kwota}{item.walutaTransakcji}
                            </td>
                        }
                        {!item.refund && !item.isRefund &&
                            <td className="cursor" onClick={() => this.showDetails(item)}>
                                {kwota}{item.walutaTransakcji}
                            </td>
                        }
                        <td className={item.subDetails.isSub && item.subDetails.expired ? "text-center, text-danger, cursor" : "text-center, cursor"}
                            onClick={() => this.showDetails(item)}
                        >
                            {item.subDetails.isSub && !item.isRefund &&
                                item.subDetails.date
                            }
                            {!item.subDetails.isSub && !item.isRefund &&
                                '-'
                            }
                            {item.isRefund &&
                                <span className="text-danger">Zwrot</span>
                            }
                        </td>
                    </tr>
                )
            })
        )
    }
    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }

        return (
            <Col xs={12} className='p-0'>
                <Toaster />
                <Row noGutters>
                    <Col xs={12} className='p-0 border'>
                        <Row noGutters>
                            <Col className='text-center'><h3>WYSZUKAJ TRANSAKCJE</h3></Col>
                        </Row>
                        <Row noGutters>
                            <Col xs={11} md={3} className=' p-2 mx-auto'>
                                <Row noGutters>
                                    <Col xs={12} className='text-center'>
                                        <Form.Control
                                            size="sm"
                                            placeholder="ID transakcji"
                                            value={this.state.idTransakcji}
                                            onChange={(e) => { this.setState({ idTransakcji: e.target.value, data: undefined }) }}
                                            disabled={this.state.fetching}
                                        />
                                    </Col>
                                    <Col xs={12} className='text-center pt-2'>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => { this.search() }}
                                            disabled={this.state.fetching}
                                        >
                                            SZUKAJ
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {this.state.fetching &&
                        <LoaderAbsoluteOverlay />
                    }
                    <Col xs={12}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th scope="col" >Data</th>
                                    <th scope="col" >Twórca</th>
                                    <th scope="col">Typ</th>
                                    <th scope="col">Kwota</th>
                                    <th scope="col">Wygasa</th>
                                </tr>
                            </thead>
                            <tbody >
                                {this.renderList()}
                            </tbody>
                        </Table>
                    </Col>
                    {this.state.data && this.state.data.length === 0 &&
                        <Col xs={12} className='text-center py-3'>
                            Brak wyników dla ID: {this.state.idTransakcji}
                        </Col>
                    }


                </Row >
            </Col>
        )
    }


}



function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,


    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                SearchZarobkiByTransId,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransakcjeView);

