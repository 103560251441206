import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const getDashboardData=() =>{
    let path=ApiUrl+"dashboard/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getDashboardData response', response.data)
        dispatch({ type: "SET_DASHBOARD_DATA", data: response.data});
      })
      .catch( (error)=> {
        // handle error
        dispatch({ type: "SET_MODEL_OF_THE_WEEK", data: null});
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const userSearch=(fd, callBack) =>{
    let path=ApiUrl+"search/user/"
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
        //console.log('userSearch response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        // handle error
        callBack({'error':true, data:error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const getTransactionList=(days, callBack) =>{
    let path=ApiUrl+"transactions/list/"+days+"/"
    console.log(path)
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        //console.log('userSearch response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        // handle error
        callBack({'error':true, data:error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }
