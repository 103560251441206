import { createStore, applyMiddleware, compose  } from "redux";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
//import mainWsMiddleware from '../middleware/mainWsMiddleware'
//import audioNotsMiddleware from '../middleware/audioNotsMiddleware'

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(thunk,))
);


export default store;