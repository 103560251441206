export const genders={
    1:'kobieta',
    2:'mężczyzna',
    3:'Płeć nieokreślona',
    4:'para k/m',
    5:'shemale',
    6:'inna niebinarna',
    7:'studio',
    8:'affiliate'
}
export const profileStatus={
    0:'Fan',
    1:'Oczekuje na weryfikacje',
    2:'Twórca',
    3:'Oczekuje na weryfikacje (oczekiwane dalsze działania)'
}