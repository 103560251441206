import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getFansSummary,
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import Table from 'react-bootstrap/Table';
import DashboardBox from "../dashboardBox";
class FansBox extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
        
        this.props.getFansSummary(this.props.user.id, this.dataFetched)
    }

    state = {
        fetching: false,
        data:{}
    }

    dataFetched = (data) => {
        console.log('KupioneBox dataFetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
    
            this.setState({ data: data})
        }
        else {
   
        }
    }

    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }



    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        return (
            <Row noGutters>
                <Col xs={12} className='border p-3 text-center'>
                    <Toaster />
                    <h5>FANI:</h5>
                    <Row noGutters>
                        <DashboardBox 
                            forceMd12={false}
                            title='Nowi dziś'
                            value={this.state.data.today}
                            buttonText=''
                            buttonAction={()=>null}

                        />
                         <DashboardBox 
                            forceMd12={false}
                            title='Aktywni fani'
                            value={this.state.data.active}
                            buttonText=''
                            buttonAction={()=>null}

                        />
                        <DashboardBox 
                            forceMd12={false}
                            title='Nieaktywni fani'
                            value={this.state.data.inactive}
                            buttonText=''
                            buttonAction={()=>null}

                        />
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
        // inWatched:state.user.inWatched,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getFansSummary,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FansBox);