
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { getDotpayRequestDetails } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import Table from 'react-bootstrap/Table';

import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";

class DotpayRequestDetails extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let id = this.props.match.params.id
    this.setState({ id: id })
    getDotpayRequestDetails(id, this.dataFetched)

  }

  componentWillUnmount() {
  }

  state = {
  }

  dataFetched = (data) => {
    console.log('dataFetched', data)
    if (!data.error) {
      this.setState({ data: data })
    }

  }
  handleWyplatyClick=(item)=>{
    this.setState({redirect:'/zarobki/details/'+item.id+'/'})
  }
  render() {
    if (!this.props.loggedIn) {
      return <Redirect to={'/login/'} />
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />
    }
    return (
      <Col xs={12} className='p-0'>
        <Row noGutters>
          <Col xs={12} className='text-center upperCase py-3'>
            <h3>SZCZEGÓŁY TRANSAKCJI (DOTPAYREQUESTS)</h3>
          </Col>
        </Row>
        {!this.state.data &&
          <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
            <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
          </Container>
        }
        {this.state.data &&
          <Row noGutters>
            <Col xs={12} className='px-3'>
              <Row noGutters>
                <Col xs={12} md={6} className='pb-4'>
                  <h5>Provider: {this.state.data.provider} </h5>
                  <p>
                    <strong>ID transakcji: </strong> {this.state.data.operationNumber}
                  </p>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={12} md={6} className='pb-4'>
                  <h5>Kupujący:</h5>
                  {this.state.data.user.displayName} - Szczegóły konta link
                </Col>
                <Col xs={12} md={6}>
                  <h5>Sprzedajacy:</h5>
                  {this.state.data.blogger.displayName} - Szczegóły konta link
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={12} md={8} className='py-3'>
                  <h5>Wpłacono:</h5>
                  <Table  bordered >
                    <thead>
                      <tr>
                        <th>Waluta wpłaty</th>
                        <th>Cena bazowa</th>
                        <th> Markup serwisu</th>
                        <th> Vat</th>
                        <th> Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="upperCase">
                          {this.state.data.wplacono.currency}
                        </td>
                        <td>
                          {this.state.data.wplacono.price} {this.state.data.wplacono.currency}
                        </td>
                        <td>
                          {this.state.data.wplacono.markup} {this.state.data.wplacono.currency}
                        </td>
                        <td>
                          {this.state.data.wplacono.vat} {this.state.data.wplacono.currency}
                        </td>
                        <td>
                          {this.state.data.wplacono.priceTotal} {this.state.data.wplacono.currency}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={12} md={8} className=''>
                  <h5>Wypłaty (Zarobki):</h5>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th> Waluta wypłaty </th>
                        <th> Prow. twórcy</th>
                        <th> Prow. serwisu</th>
                        <th> Markup</th>
                        <th> Vat</th>
                        <th> Total</th>
                        <th> Wypłacona</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.ZarobkiDotpayRequest.map((item, index) => {
                        return (
                          <tr key={'ZarobkiDotpayRequest'+index} 
                              onClick={()=>{this.handleWyplatyClick(item)}}
                              className='cursor'
                          >
                            <td className='upperCase'>
                              {item.rozliczono.currency}
                            </td>
                            <td className='upperCase'>
                              {item.rozliczono.kwotaDoWyplaty} {item.rozliczono.currency}
                            </td>
                            <td className='upperCase'>
                              {item.rozliczono.prowizjaSerwisu} {item.rozliczono.currency}
                            </td>
                            <td className='upperCase'>
                              {item.rozliczono.markup} {item.rozliczono.currency}
                            </td>
                            <td className='upperCase'>
                              {item.rozliczono.vat} {item.rozliczono.currency}
                            </td>
                            <td className='upperCase'>
                              {item.rozliczono.total} {item.rozliczono.currency}
                            </td>
                            <td>
                              {item.payoutStatus===0&&
                                'Nie'
                              }
                              {item.payoutStatus===1&&
                                'Oczekuje'
                              }
                              {item.payoutStatus===2&&
                                'tak'
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>

            </Col>

          </Row>
        }
      </Col>
    )
  }

}



function mapStateToProps(state) {
  return {
    authToken: state.auth.token,
    loggedIn: state.auth.loggedIn,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getDotpayRequestDetails,
      }, dispatch)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DotpayRequestDetails);

