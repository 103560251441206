import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    //  getUser,
    getVerificationPictures,
    updateVerificationPics,
    sendVerificationNotification,
    changeVerificationPicsStatus,

    sendVerification,
    ApiUrl,

} from "../../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiOutlineDown } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";
import VerificationPicture from "../verificationPicture";
import ImageAnalizeModal from "../imageAnalizeModal";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Dropzone from 'react-dropzone-uploader'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import toast, { Toaster } from 'react-hot-toast';
import DialogToast from "../dialogToast";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
const licenseKey = 'EBAD8C94-B5724B8E-8EB24C0A-8A800C2E'

class VerificationBox extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
        let id = this.props.match.params.id
        let token = localStorage.getItem("token")
        this.setState({ id: id, authToken: token },
            () => {
                this.fetchData()
            })
    }

    state = {
        verReasonsArray: [],
        verReasonsCheckboxes: [],
        showVerificationForm: false,
        sendVerificationMail: true,
        sendVerificationAlert: true,
        idPicsStatusesChanged: []
    }
    fetchData = () => {
        this.setState({ verPicturesFetching: true })
        this.props.getVerificationPictures(this.verPicDataFetched, this.state.id)

    }
    verPicDataFetched = (data) => {
        console.log('verPicDataFetched', data)
        this.setState({ verPicturesFetching: false })
        if (!data.error) {
            this.setState({ verPicsData: data })
        }
    }

    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }
    imageAnalizeModalOnHide = () => {
        this.setState({ imageAnalizeModalShow: false, imageToAnalize: undefined })
    }
    getVerPicsUploadParams = ({ meta }) => {
        return {
            url: ApiUrl + 'verifications/pictures/' + this.state.id + '/',
            headers: {
                'Authorization': 'token ' + this.state.authToken
            }

        }
    }
    // called every time a file's `status` changes
    handleVerPicsUploadChangeStatus = ({ meta, remove }, status) => {
        if (status === 'done') {
            // this.props.getVerificationPictures(this.verPicDataFetched, this.state.id)
        }
        if (status === 'headers_received') {
            this.props.getVerificationPictures(this.verPicDataFetched, this.state.id)
            remove()
        }
    }
    handleVerPicsSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    resetVerificationForm = (all = false) => {
        this.setState({
            verReasonsArray: [],
            verReasonsCheckboxes: [],
            verificationReason: undefined,
            contentState: undefined,
            editorState: undefined,
            sendVerificationMail: true,
            sendVerificationAlert: true,

        })
        if (all) {
            this.setState({ verificationSelect: 'none', showVerificationForm: false, })
        }
    }
    verificationSelectOnChange = (e) => {
        console.log('verificationSelectOnChange', e.target.value)
        let val = e.target.value
        if (val === 'none') {
            val = undefined
        }
        this.resetVerificationForm()
        this.setState({
            verificationSelect: val,
        })
    }
    onVerificationReasonCheck = (e, item) => {
        console.log('onVerificationReasonCheck', e.target.name, e.target.checked)
        // this.setState({[e.target.name]:e.target.checked})
        let verReasonsCheckboxes = JSON.parse(JSON.stringify(this.state.verReasonsCheckboxes))
        let verReasonsArray = JSON.parse(JSON.stringify(this.state.verReasonsArray))
        if (e.target.checked) {
            verReasonsArray.push(item)
            verReasonsCheckboxes.push(e.target.name)
        }
        else {
            const searchIndex = verReasonsArray.findIndex((reason) => reason.mailContentPl == item.mailContentPl);
            if (searchIndex !== -1) {
                verReasonsArray.splice(searchIndex, 1)
            }
            const searchIndex2 = verReasonsCheckboxes.findIndex((box) => box === e.target.name);
            if (searchIndex !== -1) {
                verReasonsCheckboxes.splice(searchIndex2, 1)
            }
        }
        this.setState({ verReasonsArray: verReasonsArray, verReasonsCheckboxes: verReasonsCheckboxes }, () => {
            console.log('verReasonsCheckboxes', verReasonsCheckboxes)
            let prolog = ''
            let epilog = ''
            if (this.state.verificationSelect === 'decline') {
                prolog = '<p><strong>Twoja prośba o weryfikację konta została odrzucona.</strong></p><p>Powód:</p>'
                epilog = '<p>Popraw brakujące dane i spróbuj ponownie</p><p>Pozdrawiamy<br>Watchmemore</p>'
            }
            if (this.state.verificationSelect === 'nextActions') {
                prolog = '<p><strong>Aby ukończyć weryfikację, prosimy o uzupełnienie następujących danych:</strong></p>'
                epilog = '<p>Pozdrawiamy<br>Watchmemore</p>'
            }

            this.setState({ verificationReason: undefined, contentState: undefined, editorState: undefined }, () => {
                let reasonsList = this.state.verReasonsArray.map((item, index) => {
                    return ('<li>' + item.mailContentPl + '</li>')
                })
                reasonsList = '<ul>' + reasonsList + '</ul>'
                //<ul>
                // const html = prolog + reasonsList + '<br />' + epilog
                const html = reasonsList
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    this.setState({ verificationReason: item, contentState: contentState, editorState: editorState })
                }
            })
        })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    saveVerification = (confirmed = false) => {
        if (this.state.verificationSelect === 'decline') {
            if (this.state.verReasonsArray.length === 0) {
                toast.error((t) => { return this.renderToastContent(t, 'Musisz wybrać powód odrzucenia weryfikacji!') })
                return
            }
        }
        if (this.state.verificationSelect === 'nextActions') {
            if (this.state.verReasonsArray.length === 0) {
                toast.error((t) => { return this.renderToastContent(t, 'Musisz wybrać oczekiwane dalsze działania!') })
                return
            }
        }
        toast.dismiss();

        if (!confirmed) {
            DialogToast('Czy napewno chcesz zapisać weryfikacje?', () => this.saveVerification(true))
            return
        }
        this.setState({ verificationFetching: true })
        let mailContent = ''
        if (this.state.verificationSelect === 'decline' || this.state.verificationSelect === 'nextActions') {
            mailContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        }
        const fd = new FormData()
        //fd.append('userId', this.state.id)
        fd.append('mailContent', mailContent)
        fd.append('decision', this.state.verificationSelect)

        if (this.state.verReasonsArray.length > 0) {
            let adminLogsContent = ''
            this.state.verReasonsArray.map((item, index) => {
                adminLogsContent += '<li>' + item.title + '</li>'
            })
            adminLogsContent = '<ul>' + adminLogsContent + '</ul>'
            fd.append('adminLogsContent', adminLogsContent)
        }
        if (this.state.sendVerificationMail) {
            fd.append('sendMail', true)
        }
        if (this.state.sendVerificationAlert) {
            fd.append('sendAlert', true)
        }

        console.log('this.state.id', this.state.id)
        this.props.sendVerification(fd, this.state.id, this.verificationSaved)
    }

    verificationSaved = (data) => {
        console.log('verificationSaved', data)
        this.setState({ verificationFetching: false })
        if (!data.error) {
            this.resetVerificationForm(true)
            this.setState({
                profile: data.profile,
                adminLogs: data.adminLogs,
            })
            toast.success((t) => { return this.renderToastContent(t, 'Weryfikacja została zapisana.') })
        }
        else {
            if (data.message) {
                toast.error((t) => { return this.renderToastContent(t, data.message) })
            }
            else {
                toast.error((t) => { return this.renderToastContent(t, data) })
            }
        }
    }

    showVerificationForm = () => {
        if (this.state.showVerificationForm) {
            this.resetVerificationForm(true)
        }
        this.setState({ showVerificationForm: !this.state.showVerificationForm })
    }

    changeVerificationPicsStatus = (id, status, confirmed = false) => {
        toast.dismiss();
        if (!confirmed) {
            DialogToast('Czy napewno chcesz odrzucić zdjęcia?', () => this.changeVerificationPicsStatus(id, status, true))
            return
        }
        this.setState({ verPicturesFetching: true })
        const fd = new FormData()
        fd.append('status', status)
        this.props.changeVerificationPicsStatus(id, fd, this.verificationPicsStatusChanged)
    }


    verificationPicsStatusChanged = (data) => {
        console.log('verificationPicsStatusChanged', data)
        this.setState({ verPicturesFetching: false })
        if (!data.error) {
            this.setState({ verPicsData: data })
        }
    }

    rejectPic = (name, item) => {
        if (window.confirm('Czy napewno chcesz Odrzucic to zdjęcie?')) {

        console.log('rejectPic', name, item)
        const fd = new FormData()
        fd.append(name, 'open')
        fd.append('status', 'open')
        this.props.updateVerificationPics(item.id, fd, this.idPicsUpdated)
        }
    }

    setPersonStatus = (status, item) => {
        if (window.confirm('Czy napewno chcesz zmienić status osoby na '+status+'?')) {
        console.log('rejectPic', status, item)
        const fd = new FormData()
        fd.append('status', status)
        this.props.updateVerificationPics(item.id, fd, this.idPicsUpdated)
        }
    }

    idPicsUpdated = (data) => {
        console.log('rejectFetched', data)
        if (!data.error) {
            this.setState({ verPicsData: data })
        }
        else {
            alert('Błąd')
        }
    }

    sendIdPicsNotification = (item) => {
        if (window.confirm('Czy napewno chcesz wysłać powiadomienia o zmianie statusu zdjęć? Wysylaj je wylacznie po wprowadzeniu zmian w zdjeciach profilowych.')) {
            this.setState({ IdPicsNotificationFetching: true })
            const fd = new FormData()
            fd.append('id', item.id)
            this.props.sendVerificationNotification(fd, this.idPicNotifyFetched)
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }


    }

    idPicNotifyFetched = (data) => {
        toast.success((t) => { return this.renderToastContent(t, 'Powiadomienie zostało wysłane.') })
        this.setState({ IdPicsNotificationFetching: false })
        console.log('idPicNotifyFetched', data)
    }

    renderNotifyIdPicStatusChange = (item) => {
        console.log(this.props)
        if (this.props.profile.isVerified !== 2) {
            return
        }
        return (
            <Col xs={12}>
                <Button
                    className="float-left"
                    onClick={() => this.sendIdPicsNotification(item)}
                    disabled={this.state.IdPicsNotificationFetching}
                >
                    Powiadom o Zmianie Statusu Zdjęć
                </Button>
            </Col>
        )
    }

    renderPersonAcceptDeclineBtns = (item) => {
        if (item.status == 'pending') {
            return (
                <Col xs={12} className="">
                    <Button
                        className="float-right mt-3"
                        variant={'success'}
                        onClick={() => this.setPersonStatus('accepted', item)}
                        disabled={this.state.IdPicsNotificationFetching}
                    >
                        <FaCheck /> Zaakceptuj Osobę
                    </Button>
                    <Button
                        className="float-right  mt-3 mr-3"
                        variant={'danger'}
                        onClick={() => this.setPersonStatus('declined', item)}
                        disabled={this.state.IdPicsNotificationFetching}
                    >
                        <FaTimes />  Odrzuć Osobę
                    </Button>
                </Col>
            )
        }
        if (item.status == 'accepted') {
            return (
                <Col xs={12} className="">
                    <Button
                        className="float-right  mt-3 mr-3"
                        variant={'danger'}
                        onClick={() => this.setPersonStatus('declined', item)}
                        disabled={this.state.IdPicsNotificationFetching}
                    >
                        <FaTimes />  Zmień status na Odrzucona
                    </Button>

                </Col>
            )

        }
        if (item.status == 'declined') {
            return (
                <Col xs={12} className="">
                    <Button
                        className="float-right mt-3"
                        variant={'success'}
                        onClick={() => this.setPersonStatus('accepted', item)}
                        disabled={this.state.IdPicsNotificationFetching}
                    >
                        <FaCheck /> Zmień status na Zaakceptowana
                    </Button>

                </Col>
            )
        }
    }

    renderPersons = () => {
        if (!this.state.verPicsData) {
            return null
        }

        let rendered = this.state.verPicsData.newPics.map((item, index) => {
            let idFrontUrl, idBackUrl, idSelfieUrl;
            if (item.idFront) {
                idFrontUrl = item.idFront + item.sas
            }
            if (item.idBack) {
                idBackUrl = item.idBack + item.sas
            }
            if (item.idSelfie) {
                idSelfieUrl = item.idSelfie + item.sas
            }
            let random = (Math.random() + 1).toString(36).substring(7);
            let personStatus = ''
            if (item.status === 'open') {
                personStatus = 'W trakcie uzupełniania'
            }
            if (item.status === 'pending') {
                personStatus = 'Oczekująca'
            }
            if (item.status === 'pending') {
                personStatus = 'Oczekująca'
            }
            if (item.status === 'accepted') {
                personStatus = 'Zaakceptowana'
            }
            if (item.status === 'declined') {
                personStatus = 'Odrzucona'
            }
            return (
                <Row key={'verPerson' + index} className={item.status === 'declined' ? "mb-3 trans30" : "mb-3"}>
                    <Col xs={12} className="text-left border-top border-left border-right py-2">
                        <h6>Osoba: {item.entryName},  </h6>
                        Status: {personStatus}
                    </Col>
                    {this.renderNotifyIdPicStatusChange(item)}
                    {this.renderPersonAcceptDeclineBtns(item)}
                    <Col xs={12} className="text-left border-left border-bottom border-right">
                        <Row>
                            <Col xs={12} md={3} className="p-3">
                                <strong>ID Front</strong> <small>{item.idFrontDate}</small>
                                {!idFrontUrl &&
                                    <p>Brak</p>
                                }
                                {idFrontUrl &&
                                    <>
                                        <LightGallery
                                            closeOnTap={true}
                                            //isMobile={isMobile}
                                            licenseKey={licenseKey}
                                            //   onInit={onInit}
                                            // speed={500}
                                            plugins={[lgZoom]}
                                        >
                                            <a href={idFrontUrl}>
                                                <Image src={idFrontUrl}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    className={item.idFrontStatus === 'open' ? "trans30" : ""}

                                                />
                                            </a>
                                        </LightGallery>
                                        {item.idFrontStatus === 'open' &&
                                            <Row noGutters>
                                                <Col xs={12} className=' pb-1 pt-1'>
                                                    Oczekuje na wymiane
                                                </Col>
                                            </Row>
                                        }
                                        {item.idFrontStatus === 'locked' && item.status !=='accepted' &&
                                            <Row noGutters>
                                                <Col xs={12} className=' pb-1 pt-1'>
                                                    <Button onClick={() => this.rejectPic('idFrontStatus', item)}>Odrzuć Zdjęcie</Button>
                                                </Col>
                                            </Row>
                                        }
                                    </>
                                }

                                {console.log(item)}

                            </Col>
                            <Col xs={12} md={3} className="p-3">
                                <strong>ID Back</strong>   <small>{item.idFrontDate}</small>
                                {!idBackUrl &&
                                    <p>Brak</p>
                                }
                                {idBackUrl &&
                                    <>
                                        <LightGallery
                                            closeOnTap={true}
                                            //isMobile={isMobile}
                                            licenseKey={licenseKey}
                                            //   onInit={onInit}
                                            // speed={500}
                                            plugins={[lgZoom]}
                                        >
                                            <a href={idBackUrl}>
                                                <Image src={idBackUrl}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    className={item.idBackStatus === 'open' ? "trans30" : ""}

                                                />
                                            </a>
                                        </LightGallery>
                                        {item.idBackStatus === 'open' &&
                                            <Row noGutters>
                                                <Col xs={12} className=' pb-1 pt-1'>
                                                    Oczekuje na wymiane
                                                </Col>
                                            </Row>
                                        }
                                        {item.idBackStatus === 'locked' && item.status !=='accepted' &&
                                            <Row noGutters>
                                                <Col xs={12} className=' pb-1 pt-1'>
                                                    <Button onClick={() => this.rejectPic('idBackStatus', item)}>Odrzuć Zdjęcie</Button>
                                                </Col>
                                            </Row>
                                        }
                                    </>
                                }


                            </Col>
                            <Col xs={12} md={3} className="p-3">
                                <strong>ID Selfie</strong>   <small>{item.idFrontDate}</small>
                                {!idSelfieUrl &&
                                    <p>Brak</p>
                                }
                                {idSelfieUrl &&
                                    <>
                                        <LightGallery
                                            closeOnTap={true}
                                            //isMobile={isMobile}
                                            licenseKey={licenseKey}
                                            //   onInit={onInit}
                                            // speed={500}
                                            plugins={[lgZoom]}
                                        >
                                            <a href={idSelfieUrl}>
                                                <Image src={idSelfieUrl}
                                                    style={{ width: '100%', height: 'auto' }}
                                                    className={item.idSelfieStatus === 'open' ? "trans30" : ""}

                                                />
                                            </a>
                                        </LightGallery>
                                        {item.idSelfieStatus === 'open' &&
                                            <Row noGutters>
                                                <Col xs={12} className=' pb-1 pt-1'>
                                                    Oczekuje na wymiane
                                                </Col>
                                            </Row>
                                        }
                                        {item.idSelfieStatus === 'locked' && item.status !=='accepted' &&
                                            <Row noGutters>
                                                <Col xs={12} className=' pb-1 pt-1'>
                                                    <Button onClick={() => this.rejectPic('idSelfieStatus', item)}>Odrzuć Zdjęcie</Button>
                                                </Col>
                                            </Row>
                                        }
                                    </>
                                }

                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        })
        return rendered

    }

    render() {
        console.log('verification view props', this.props)
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        if (this.state.fetching) {
            return (
                <div style={{ zIndex: '100', backgroundColor: 'rgba(255,255,255,0.4)', position: 'absolute', left: '0px', right: '0px', top: '0px', bottom: '0px' }}>
                    <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                        <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                    </Container>
                </div>
            )
        }
        let showVerificationForm = false
        if (this.props.profile && (this.props.profile.isVerified === 1 || this.props.profile.isVerified === 3 || this.state.showVerificationForm === true)) {
            showVerificationForm = true
        }
        if (this.props.profile) {
            return (
                <Row noGutters>
                    <Col xs={12} className='p-0'>
                        <Toaster />
                        <Row noGutters>
                            <Col xs={12} className='border p-3'>
                                <Row noGutters>
                                    {/**ZDJĘCIA WERYFIKACYJNE */}
                                    <Col xs={12} className='text-center border p-3'>
                                        <Row noGutters>
                                            <Col xs={12} className='text-center pb-2'>
                                                <h5>ZDJĘCIA WERYFIKACYJNE:</h5>
                                            </Col>
                                        </Row>
                                        {this.state.verPicturesFetching &&
                                            <div style={{ zIndex: '100', backgroundColor: 'rgba(255,255,255,0.4)', position: 'absolute', left: '0px', right: '0px', top: '0px', bottom: '0px' }}>
                                                <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                                                    <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                                                </Container>
                                            </div>
                                        }
                                        <Row noGutters>
                                            <Col xs={12} className='text-center pb-2 text-black-50'>
                                                <h5>NOWA WERYFIKACJA:</h5>
                                            </Col>
                                        </Row>
                                        {this.state.verPicsData && this.state.verPicsData.newPics.length === 0 &&
                                            <Row noGutters>
                                                <Col xs={12} className='text-center pb-2 text-black-50'>
                                                    Brak nowych zdjęć weryfikcyjnych
                                                </Col>
                                            </Row>
                                        }
                                        {this.renderPersons()}
                                        <Row noGutters>
                                            <Col xs={12} className='text-center pb-2 mt-3 text-black-50'>
                                                <h5>STARA WERYFIKACJA:</h5>
                                            </Col>
                                        </Row>
                                        {this.state.verPicsData && this.state.verPicsData.oldPics.length === 0 &&
                                            <Row noGutters>
                                                <Col xs={12} className='text-center pb-2 text-black-50'>
                                                    Brak starych zdjęć weryfikcyjnych
                                                </Col>
                                            </Row>
                                        }
                                        {this.state.verPicsData &&
                                            <Row noGutters>
                                                {this.state.verPicsData.oldPics.map((item, index) => {
                                                    return <VerificationPicture
                                                        key={'vpic' + index}
                                                        item={item}
                                                        onClick={() => { this.setState({ imageToAnalize: item, imageAnalizeModalShow: true }) }}
                                                    />
                                                })}
                                            </Row>
                                        }
                                        <Row noGutters>
                                            <Col xs={10} md={4} className='mx-auto py-2'>
                                                <Dropzone
                                                    getUploadParams={this.getVerPicsUploadParams}
                                                    onChangeStatus={this.handleVerPicsUploadChangeStatus}
                                                    onSubmit={this.handleVerPicsSubmit}
                                                    accept="image/*,audio/*,video/*"
                                                    inputContent="Dodaj pliki"
                                                    inputWithFilesContent="Dodaj pliki"
                                                    submitButtonContent="zapisz"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/**FORMULARZ WERYFIKACYJNY */}
                                    <Col xs={12} className='text-center border p-3'>
                                        <Row noGutters>
                                            <Col xs={12} className='text-center pb-1'>
                                                <h4>WERYFIKACJA TWÓRCY:</h4>
                                                {this.props.profile.isVerified === 0 &&
                                                    <span className='text-black-50'>
                                                        Profil niezweryfikowany
                                                    </span>
                                                }
                                                {this.props.profile.isVerified === 1 &&
                                                    <span className='text-black-50'>
                                                        Profil oczekuje na weryfikacje
                                                    </span>
                                                }
                                                {this.props.profile.isVerified === 2 &&
                                                    <span className='text-black-50'>
                                                        Profil zweryfikowany
                                                    </span>
                                                }
                                                {this.props.profile.isVerified === 3 &&
                                                    <span className='text-black-50'>
                                                        Profil oczekuje na weryfikacje<br></br>.
                                                        Wysłana prośba o dalsze działania.
                                                    </span>
                                                }
                                                {(this.props.profile.isVerified === 0 || this.props.profile.isVerified === 2) &&
                                                    <Row noGutters>
                                                        <Col xs={12} onClick={() => { this.showVerificationForm() }} className='text-center text-primary '>
                                                            {!showVerificationForm && <span>Zmień<AiOutlineDown /></span>}{showVerificationForm && <span>Ukryj<AiOutlineUp /></span>}
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                        {showVerificationForm &&
                                            <Row noGutters>
                                                <Col xs={12} className='text-center pt-3'>
                                                    <h5>ZWERYFIKUJ PROFIL:</h5>
                                                </Col>
                                            </Row>
                                        }
                                        {showVerificationForm &&
                                            <Row noGutters>
                                                <Col xs={11} md={6} className='text-center mx-auto pt-1'>
                                                    <Form.Control as="select"
                                                        onChange={(e) => this.verificationSelectOnChange(e)}
                                                        disabled={this.state.verificationFetching}
                                                    >
                                                        <option value="none">Wybierz decyzje...</option>
                                                        <option value='accept'>Zaakceptuj twórcę</option>
                                                        <option value='decline'>Odrzuć zgłoszenie</option>
                                                        <option value='nextActions'>Dalsze działania</option>
                                                    </Form.Control>
                                                </Col>
                                            </Row>
                                        }

                                        <Row noGutters>
                                            {this.state.verificationSelect === 'decline' &&
                                                <Col xs={11} md={6} className='text-left mx-auto pt-3'>
                                                    {this.props.declineReasons.map((item, index) => {
                                                        return (
                                                            <Form.Check
                                                                key={'verReasonRadio' + index}
                                                                label={item.shortDecs}
                                                                name={'decReas' + item.id}
                                                                value={this.state.verReasonsCheckboxes['decReas' + item.id] ? true : false}
                                                                type={'checkBox'}
                                                                onChange={(e) => this.onVerificationReasonCheck(e, item)}
                                                                id={'verDecisionRadio' + index}
                                                                //  checked={this.state.verReasonsCheckboxes['decReas'+item.id]? true : false}
                                                                disabled={this.state.verificationFetching}
                                                            />
                                                        )
                                                    })}
                                                </Col>
                                            }
                                            {this.state.verificationSelect === 'nextActions' &&
                                                <Col xs={11} md={6} className='text-left mx-auto pt-3'>
                                                    {this.props.nextActions.map((item, index) => {
                                                        return (
                                                            <Form.Check
                                                                key={'verReasonRadio2' + index}
                                                                label={item.shortDecs}
                                                                name={'nextActions' + item.id}
                                                                value={this.state.verReasonsCheckboxes['nextActions' + item.id] ? true : false}
                                                                type={'checkbox'}
                                                                onChange={(e) => this.onVerificationReasonCheck(e, item)}
                                                                id={'verDecision2Radio' + index}
                                                                //   checked={this.state.verReasonsCheckboxes['nextActions'+item.id]? true : false}
                                                                disabled={this.state.verificationFetching}
                                                            />
                                                        )
                                                    })}
                                                </Col>
                                            }
                                        </Row>
                                        {this.state.verificationReason &&
                                            <Row noGutters>
                                                <Col xs={11} md={6} className='text-center mx-auto mt-3 pt-2 pb-3 px-2 border'>
                                                    <Editor
                                                        editorState={this.state.editorState}
                                                        wrapperClassName="demo-wrapper"
                                                        editorClassName="demo-editor"
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        {this.state.verificationSelect && this.state.verificationSelect !== 'none' &&
                                            <Row noGutters>
                                                <Col xs={11} className='text-center mx-auto mt-3 text-center '>
                                                    <Form.Check
                                                        label={'Wyślij maila z powiadomieniem'}
                                                        name={'sendVerificationMail'}
                                                        value={this.state.sendVerificationMail}
                                                        type={'checkbox'}
                                                        onChange={(e) => this.setState({ sendVerificationMail: e.target.checked })}
                                                        id={'verMailCheck'}
                                                        checked={this.state.sendVerificationMail}
                                                        disabled={this.state.verificationFetching}
                                                    />
                                                </Col>
                                                <Col xs={11} className='text-center mx-auto mt-3 text-center '>
                                                    <Form.Check
                                                        label={'Wyślij alert z powiadomieniem'}
                                                        name={'sendVerificationAlert'}
                                                        value={this.state.sendVerificationAlert}
                                                        type={'checkbox'}
                                                        onChange={(e) => this.setState({ sendVerificationAlert: e.target.checked })}
                                                        id={'verMailCheck'}
                                                        checked={this.state.sendVerificationAlert}
                                                        disabled={this.state.verificationFetching}
                                                    />
                                                </Col>
                                                <Col xs={11} md={6} className='text-center mx-auto mt-3 pt-2 pb-3 px-2 '>
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() => { this.saveVerification() }}
                                                        disabled={this.state.verificationFetching}
                                                    >
                                                        ZAPISZ WERYFIKACJE
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <ImageAnalizeModal show={this.state.imageAnalizeModalShow} src={this.state.imageToAnalize} />
                    </Col>
                </Row>
            )
        }
    }


}

function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,


    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getVerificationPictures,
                sendVerification,
                updateVerificationPics,
                sendVerificationNotification,
                changeVerificationPicsStatus,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerificationBox);
