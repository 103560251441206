import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    addAdminNotes,
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import toast, { Toaster } from 'react-hot-toast';
import DialogToast from "../dialogToast";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

class AdminLogsBox extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
        // this.setState({ reason:this.props.inSuspected.reason })
    }
    state = {
        show: false,
    }


    renderLogs = () => {
        return (
            <ListGroup>
                {this.props.adminLogs.map((item, index) => {
                    return (
                        <ListGroup.Item key={'notatka' + index}>
                            <small>
                                <div><strong>{item.action}</strong></div>
                                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                <div className='text-black-50'>
                                    {item.date} {item.admin.displayName}
                                </div>
                            </small>
                        </ListGroup.Item>
                    )
                })
                }
            </ListGroup>
        )

    }
    render() {
        return (
            <Row noGutters>
                <Col xs={12} className='border p-3'>
                    <Toaster />
                    <Row noGutters>
                        <Col xs={12} className='text-center border p-3'>

                            <Row noGutters>
                                <Col xs={12} className='text-center pb-2'>
                                    <h5>HISTORIA DZIAŁAŃ:</h5>
                                </Col>
                                <Col onClick={() => { this.setState({ show: !this.state.show }) }} xs={12} className='text-center pb-2' >
                                    {this.state.show &&
                                        <span className="text-primary cursor">Ukryj<AiOutlineUp /></span>
                                    }
                                    {!this.state.show &&
                                        <span className="text-primary cursor">Pokaż<AiOutlineDown /></span>
                                    }
                                </Col>
                            </Row>
                            {this.state.show &&
                                <Row noGutters>
                                    <Col xs={12} md={5} className='mx-auto text-left' style={{ maxHeight: '60vh', overflow: 'auto' }}>
                                        {this.renderLogs()}
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
        adminLogs: state.user.adminLogs,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                addAdminNotes,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminLogsBox);