
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {getVerificationList} from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import {Oval} from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import {BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import { BsClock } from 'react-icons/bs';
import {Link} from "react-router-dom";

class VerificationListView extends Component {
    constructor(props) {
      super(props);
    }
 
    componentDidMount() {
        this.props.getVerificationList(this.dataFetched)
     
    }
    
    componentWillUnmount() {
    }

    state={
    }

    dataFetched=(data)=>{
        console.log('dataFetched', data)
        if(!data.error){
            this.setState({data:data})
        }
        
    }

    render() {
      if(!this.props.loggedIn){
        return <Redirect to={'/login/'} />
      }
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} push/>
      }
        return (
            <Col xs={12} className='p-0'>
                <Row noGutters>
                    <Col xs={12}  className='text-center upperCase py-3'>
                        <h3>WERYFIKACJE</h3>
                    </Col>
                </Row>
                {!this.state.data&&
                    <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                        <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                    </Container>
                }
                {this.state.data&&
                    <Row noGutters>
                        <Col xs={12}>
                            <table className="table">
                                <thead>
                                <tr> 
                                    <th scope="col" ></th>
                                    <th scope="col" className='text-center'>Nick</th>
                                    <th scope="col" className=" d-none d-md-table-cell text-center">Email</th>
                                    <th scope="col" className='text-center'>Data</th>
                                    <th scope="col" className='text-center'>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, index)=>{
                                        let status=<span className='text-warning'>New</span>
                                        if(item.status===3){
                                            status=<BsClock style={{fontSize:'26px'}}/>
                                        }
                                        return(
                                            <tr key={'tiItem'+index} className='textBlueGrey'>
                                                <td className="strong text-center">
                                                    <Image roundedCircle height={50} src={item.avatar} />
                                                </td>
                                                <td className="strong text-center py-4">
                                                    <Link to={'/user/'+item.userId+'/'} target={'_blank'}>
                                                        {item.displayName}
                                                    </Link>
                                                </td>
                                                <td className=" d-none d-md-table-cell text-center py-4">
                                                    {item.email}
                                                </td>
                                                <td className=" text-center py-4">
                                                    {item.date}
                                                </td>
                                                <td className="strong text-center py-4">
                                                    {status}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                }
                <UserSearchBox />

            </Col>
        )}
      
  }



  function mapStateToProps(state) {
    return {

      authToken:state.auth.token,
      loggedIn:state.auth.loggedIn,
      dashboardData:state.dashboard.dashboardData,

    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      dispatch,
      ...bindActionCreators(
          { 
            getVerificationList,
        }, dispatch)
    }
  }
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(VerificationListView);

