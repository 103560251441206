
import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { generatePayouts } from "../actions/index";
import { Oval } from 'react-loader-spinner'

import { Link } from "react-router-dom";

import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

function PayoutsListModal(props) {
  console.log(props.data)
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState(undefined);
  const [saved, setSaved] = useState(false);

  const fetch = (save = false) => {
    setFetching(true)
    generatePayouts(save, fetched)
  }
  const fetched = (data) => {
    console.log('fetched', data)
    setFetching(false)
    if (!data.error) {
      console.log('data.data', data.data)
      setData(data.data)
      setSaved(data.saved)
    //  CalculteSum()
    }
    else {
      console.log(data)
    }

  }
  const CalculteSum = () => {
    let sum = 0;
    data.forEach(element => {
      sum += element.amount;
    });
    this.setState({ sum: sum })
  }
  const onHide = () => {
    setData(undefined)
    setSaved(false)
    setFetching(false)
    props.onHide()
  }
  return (

    <Modal

      centered
      show={props.show}
      onHide={() => onHide()}
      onShow={() => fetch()}
      dialogClassName="modal-90h  flex-column"
    >
      <Modal.Body className='p-0  '>
        <Row noGutters className=''>
          <Col xs={12} className='border-bottom text-center py-2 textBlueGrey'>
            <Row noGutters>
              <Col xs={12} className='text-center' >
                <span style={{ fontSize: '23px' }}>WYPŁATY</span>
              </Col>
            </Row>
          </Col>
        </Row>
        {fetching &&
          <div style={{ zIndex: '100', backgroundColor: 'rgba(255,255,255,0.4)', position: 'absolute', left: '0px', right: '0px', top: '0px', bottom: '0px' }}>
            <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
              <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
            </Container>
          </div>
        }
        <Row noGutters className="overflow-auto  border" style={{ height: '76vh', overflow: 'auto' }}>
          {data && data.length === 0 &&
            <Col className="text-center py-3 " >
              <h4>Brak nowych wypłat.</h4>
            </Col>
          }
          {saved &&
            <Col className="text-center strong py-4 text-success" >
              <p>Wypłaty zostały poprawnie zapisane.</p>
            </Col>
          }
          {data && data.length > 0 &&
            <Col xs={12}>
              <Row noGutters>
                <Col xs={12}>
                  <table className="table stransactionListTable">
                    <thead>
                      <tr>
                        <th scope="col" className='text-center' >Twórca:</th>
                        <th scope="col">Kwota:</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.map((item, index) => {
                        return (
                          <tr key={'tiItem' + index}>
                            <td className="strong text-center capitalize">
                              <strong>
                                <Link to={{ pathname: 'https://www.watchmemore.com/' + item.urName + '/' }} target={'_blank'}>
                                  {item.displayName}
                                </Link>
                              </strong>
                            </td>
                            <td className="strong upperCase">
                              <strong>
                                {item.amount}{item.currency}
                              </strong>
                            </td>
                          </tr>
                        )
                      })
                      }
                     
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          }


        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row noGutters>
            <Col xs={6} className='text-left'>
              {saved &&
                <span className="text-success">ZAPISANE!</span>
              }
              {!saved && data && data.length > 0 &&
                <Button variant={'danger'} disabled={fetching} onClick={() => { fetch(true) }}>
                  ZAPISZ WYPŁATY
                </Button>
              }
            </Col>
            <Col xs={6} className='text-right'>
              <Button variant={'outline-danger'} onClick={() => onHide()}>Close</Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  )
}
export default PayoutsListModal