
import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Oval } from 'react-loader-spinner'
import Image from 'react-bootstrap/Image';
import { genders, profileStatus } from '../../constants/common'
import { Link } from "react-router-dom";

import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

function Header(props) {
    return (
        <Row noGutters>
            <Col xs={12} className='border py-3 text-center'>
                <Row noGutters>
                    <Col xs={12} >
                        <Image roundedCircle height={150} src={props.profile.avatar} /><br></br>
                        <Button variant="redSm" size={'sm'}>
                            ZRESETUJ ZDJĘCIE PROFILOWE
                        </Button>
                    </Col>
                    <Col xs={12} >
                        <Row noGutters>
                            <Col xs={12} className='text-center pt-2 upperCase'>
                                <Link to={{ pathname: 'https://www.watchmemore.com/' + props.profile.urlName + '/' }} target={'_blank'}>
                                    <h4>{props.profile.displayName}</h4>
                                </Link>
                            </Col>
                            <Col xs={12} className='text-center strong capitalize cardSmallText textBlueGrey'>
                                {genders[props.profile.gender]}
                            </Col>
                            <Col xs={12} className={props.profile.isVerified === 1 ? 'text-center capitalize cardSmallText text-success' : 'text-center capitalize cardSmallText textBlueGrey'}>
                                {profileStatus[props.profile.isVerified]}
                            </Col>
                            <Col xs={12} className='text-center cardSmallText textBlueGrey'>
                                <a href={'mailto:' + props.user.email}>
                                    {props.user.email}
                                </a>
                            </Col>
                            <Col xs={12} className='text-center cardSmallText textBlueGrey'>
                                ID: {props.user.id}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}
export default Header