import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
//import { injectIntl, FormattedMessage } from 'react-intl'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Masonry from 'react-masonry-css'

const aHrefsToText = (text) => {
    //fix dla starych powiadomien o aktualizacji galerii w chatach.
    //Obecne leca o FK, stare poprostu wrzucaly w tekscie <a hrefs... .
    var ATagRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)">([^<]*)<\/a>/;
    var match = text.match(ATagRegex);
    var href, anchor, userName, contIdValue
    if (match) {
      href = match[1];
      anchor = match[2]
      regex = /\/([^\/]+)\//;
      match = regex.exec(href);
      if (match) {
        userName = match[1];
      }
      var regex = /contId=([^&]+)/;
      var match = regex.exec(href);
      if (match) {
        contIdValue = match[1];
      }
      // console.log('aHrefsToText href', href)
      //  console.log('aHrefsToText anchor', anchor)
      //  console.log('aHrefsToText userName', userName)
      // console.log('aHrefsToText contIdValue', contIdValue)
      if (userName && contIdValue) {
        let newlink = '/' + userName + '/ppv/' + contIdValue + '/'
        //  let linkTag = '{type:"linkTag", to:"' + newlink + '", anchor:"' + anchor + '"}'
        let linkTag = JSON.stringify({ type: "linkTag", to: newlink, anchor: anchor })
        
        var modifiedString = text.replace(ATagRegex, linkTag);
        // console.log('aHrefsToText modifiedString', modifiedString)
        return 'https://www.watchmemore.com'+newlink
        return <Link className='userLink' key={'userLink2' + anchor+newlink} to={newlink}>{anchor}</Link>
        return modifiedString
      }
  
    }
    return text
  }

const parseTextToLinks = (value) => {
    const spaceSplitRegex = /\s+/;
    const hashTagsrule = /([#|＃][^\s]+)/g;
    var regularLinkRule = /[^<]*(<a href="([^"]+)">([^<]+)<\/a>)/g
    const xxxuserLink = /@(\w+)\s/g;
    const userLinkRule = /@\S+/g;
    var myLinkTagRule = /{([^{}]+)}/g;
    var rule =  /(\b(https?:\/\/)?(localhost|watchmemore\.com|www\.watchmemore\.com|watchmetestfront-watchmedevfront\.azurewebsites\.net)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]\/?)/ig
  
    if (value.match(regularLinkRule)) {
      //fix dla starych powiadomien o aktualizacji galerii w chatach.
      value = aHrefsToText(value)
    }
  
    let result = value.split(spaceSplitRegex).map((chunk) => {
      if (chunk.match(rule)) {
        //console.log('parseTextToLinks chunk.match', chunk)
        var matches = [];
        var lastIndex = 0;
        var match;
        var lastIndex = 0;
        while ((match = rule.exec(chunk)) !== null) {
          // Zachowujemy tekst między ostatnim a aktualnym dopasowaniem
          matches.push(chunk.substring(lastIndex, match.index));
          // Zachowujemy aktualnie dopasowany link
          var url = new URL(match[1])
          matches.push(<> <Link className="generated" to={url.pathname + url.search}>{match[0]}</Link> </>);
          // Ustawiamy indeks ostatniego dopasowania
          lastIndex = rule.lastIndex;
        }
        matches.push(chunk.substring(lastIndex));
        return matches
        // Zachowujemy pozostały tekst po ostatnim dopasowaniu
        //  var url = new URL(chunk)
        //   return <Link className="generated" to={url.pathname + url.search}>{chunk}</Link>
      }
      else if(chunk.match(hashTagsrule)) {
        //  console.log('parseTextToLinks chunk.match', chunk)
        return <> <Link key={'hashtag' + Math.random().toString(36).substring(7)} to={'/search/?search=' + '%23' + chunk.substring(1)}>{chunk}</Link> </>
      }
      else if (chunk.match(userLinkRule)) {
        //console.log('parseTextToLinks userLinkRule chunk.match ', chunk)
        const matches = chunk.match(userLinkRule);
        if (!matches) {
          // Jeśli nie ma żadnych dopasowań, zwróć oryginalny tekst
          return chunk;
        }
        const parts = chunk.split(userLinkRule);
        const result = [];
        parts.forEach((part, index) => {
          // Dodaj oryginalny tekst jako pierwszy element
          result.push(part);
          // Jeśli istnieje dopasowanie dla aktualnego indeksu, zamień go na link
          if (matches[index]) {
            result.push(
              <> <Link className='userLink' key={'userLink' + index} to={'/' + matches[index].trim().substring(1) + '/'}>{matches[index]}</Link> </>
            );
          }
        });
  
        return result;
      }
      else{
        return chunk+' '
      }
  
    })
  
  
  
  
    return result
  }

function ChatMessageItem(props) {
   // console.log(props)
    const [data, setData] = useState(props.data);
    const [media, setMedia] = useState(null);
    const [videoProcessingWarning, setVideoProcessingWarning] = useState(false);
    useEffect(() => {
        //  console.log('useEffect', props.data)
        if (props.data !== data) {
            setData(props.data);
            setTimeout(() => {
                prepareMediaData()
            }, 10)

        }
        //  

    })
    // useEffect(() => {
    // prepareMediaData()
    //    })

    const [blockFetching, setBlockFetching] = useState(false);
    if (!data) {
        //  setData(props.data)
    }

    //   console.log('ChatMessageItem data', data)



    let me = true
    let liClass = 'you '
    if (props.bloggerId !== props.data.sender.id) {
        liClass = 'me'
        me = false
    }



    const prepareMediaData = () => {
        let mediaTmp = []
        //     console.log('useEffect prepareMediaData', data)
        if (data.filename || data.videoname) {
            mediaTmp.push(
                {
                    filename: data.filename,
                    thumbnail: data.thumbnail,
                    videoname: data.videoname,
                    videoHls: data.videoHls,
                    videoSpriteA: data.videoSpriteA,
                    videoSpriteB: data.videoSpriteB,
                    videoSpriteC: data.videoSpriteC,
                    videoSpriteD: data.videoSpriteD,
                    videoThumbnail: data.videoThumbnail,

                }
            )
        }
        if (data.media) {
            data.media.map((item, index) => {
                mediaTmp.push(item)
            })
        }
        if (data.filesLeft) {

            for (let i = 0; i < data.filesLeft; i++) {
                mediaTmp.push({ spinner: true })
            }

        }
        setMedia(mediaTmp)
    }

    if (!media) {
        prepareMediaData()
    }


    const renderPlayerBtn = () => {
        return (
            <div className="playerPlayBtn" ><i>
                <svg version="1.1" className="play" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="50px" width="50px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xml="preserve" space="preserve">
                    <path className="stroke-solid" fill="none" stroke="" d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
                          C97.3,23.7,75.7,2.3,49.9,2.5"></path>
                    <path className="icon" fill="" d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"></path>
                </svg>
            </i>
            </div>
        )
    }
    const renderMedia = () => {
        //    console.log('renderMedia',media)
        if (!media || media.length === 0) {
            return null
        }
        let images = []
        let videos = []
        let audios = []
        let spinners = []

        media.map((item, inded) => {
            if (item.filename) {
                images.push(item)
            }
            if (item.videoname) {
                videos.push(item)
            }
            if (item.audio) {
                audios.push(item)
            }
            if (item.spinner) {
                item.id = Math.random().toString(36)
                spinners.push(item)
            }
        })
        let rendered = []
        spinners.map((item, index) => {
            rendered.push(
                <div className="mediaItemWrapper" key={'ChatMediaItemSp' + item.id} >
                    <div className="mediaItem spinner" >
                        {item.id}
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            )
        })
        audios.map((item, index) => {
            rendered.push(
                <div className="audioItemWrapper" key={'ChatMediaItemAudio' + item.id} >
                    <AudioPlayer
                        src={item.audio}
                        //  onPlay={e => console.log("onPlay")}
                        layout={'horizontal-reverse'}
                        header={<div><strong>Audio</strong></div>}
                        showJumpControls={false}
                    // other props here
                    />
                </div>
            )
        })

        videos.map((item, index) => {
            if (item.videoSpriteA) {
                rendered.push(
                    <div className="mediaItemWrapper" key={'ChatMediaItemVid' + item.id} >
                        <div className="mediaItem" onClick={() => props.setlightboxData({ media: media.reverse(), initialItem: item })}>
                            <img src={item.videoSpriteA}></img>
                            {renderPlayerBtn()}
                        </div>
                    </div>
                )
            }
            else {
                if(!videoProcessingWarning){
                    setVideoProcessingWarning(true)
                }
                rendered.push(
                    <div className="mediaItemWrapper" key={'ChatMediaItemVid' + item.id} >
                        <div className="mediaItem dumb" onClick={() => props.setlightboxData({ media: media.reverse(), initialItem: item })}>
                            {renderPlayerBtn()}
                            <i class="fa fa-film dumbBg" aria-hidden="true"></i>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAABsCAQAAACWC3UZAAAAqElEQVR42u3RAQ0AAAzCsOPf9GUQkk7CmlO1WAAAgAAAEAAAAgBAAAAIAAABACAAAAQAgAAAEAAAAgBAAAAIAAABACAAAAQAgAAAEAAAAgBAAAAIAAABACAAAAQAgAAAEAAAAgBAAAAIAAABACAAAAAIAAABACAAAAQAgAAAEAAAAgBAAAAIAAABACAAAAQAgAAAEAAAAgBAAAAIAAABACAAAAQAgAAs9Th3AG0S5LjzAAAAAElFTkSuQmCC"></img>
                        </div>
                    </div>
                )
            }
        })

        let renderedImages=[]
        images.map((item, index) => {
            if (item.thumbnailmini) {

                renderedImages.push(
                    <img src={item.thumbnailmini} onClick={() => props.setlightboxData({ media: media.reverse(), initialItem: item })} key={'ChatMediaItemImg' + index}></img>

                )
            }

            else {

                renderedImages.push(
                    <img src={item.thumbnail} onClick={() => props.setlightboxData({ media: media.reverse(), initialItem: item })} key={'ChatMediaItemImg' + index + Date.now()}></img>
                )

            }
            //zwracamy thumbsy i generujemy galerie?
        })


        let temp = []

        images.map((item, index) => {
            if (item.thumbnailmini) {

                temp.push(
                    <div className="mediaItemWrapper" key={'ChatMediaItemImg' + index} >
                        <div className="mediaItem" onClick={() => props.setlightboxData({ media: media.reverse(), initialItem: item })}>
                            <img src={item.thumbnailmini}></img>
                        </div>
                    </div>
                )
            }

            else {

                temp.push(
                    <div className="mediaItemWrapper" key={'ChatMediaItemImg' + index + Date.now()} >
                        <div className="mediaItem" onClick={() => props.setlightboxData({ media: media.reverse(), initialItem: item })}>
                            <img src={item.thumbnail}></img>
                        </div>
                    </div>
                )

            }
            //zwracamy thumbsy i generujemy galerie?
        })
        return (
            <div className="mediaBox">
                <div className=" w-100 float-left">
                    {rendered}
                </div>
                {videoProcessingWarning&&
                <div className="w-100 float-left">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> W trakcie konwersji
                    </div>
                }
               
                <Masonry
                    //breakpointCols={3}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                    breakpointCols={{
                        default: 2,
                        1100: 2,
                    }}
                >
                    {renderedImages}
                </Masonry>
                
            </div>
        )
    }

    const getText=(reply)=>{
        if(reply.startsWith('Hej, nowa <a href')){
            return <>Hej, nowa <Link className="generated" to={parseTextToLinks(reply)}>Galeria Pay Per View</Link> w moim profilu. Zapraszam.</>
        }
        else{
            return parseTextToLinks(reply)
        }
        
    }
    const renderMessageContent = () => {
        return (
            <div className="chatContent">
                  <div className="mb-2"><strong>{props.data.sender.displayName}</strong></div>
                {data.reply &&
                    <p >
                        {/** 
                        <span className='text' dangerouslySetInnerHTML={{ __html: parseTextToLinks(data.reply) }}></span>
                        */}
                        <span className='text' >{getText(data.reply)}</span>
                        {/**
            <em>Size: 106kb <ins>download Complete</ins></em> 
            */}
                    </p>
                }


                {renderMedia()}
                {props.renderOverlayTrigger}
            </div>
        )
    }

    const renderPPVShare = () => {
        return (
            <div className="chatContent ppvShare">
                <div className="itemTitle">PPV SHARE</div>
                <div className="poster">
                    <Link to={data.ppv.url} target="_blank">
                        <img src={data.ppv.poster} />
                    </Link>
                </div>
                <Link to={data.ppv.url} target="_blank">
                    <div className="infoMeta">
                        <p className="msgTitle">{data.ppv.title}</p>
                        <p className="">{data.ppv.desc}</p>
                    </div>

                    <div className="we-video-info">
                        <ul>
                            <li>
                                <span className="views" title='zdjęć'>
                                    <i className="fa fa-file-image-o" aria-hidden="true"></i>
                                    <ins>{data.ppv.imagesCount}</ins>
                                </span>
                            </li>
                            <li>
                                <span className="views" title='filmów'>
                                    <i class="fa fa-film" aria-hidden="true"></i>
                                    <ins>{data.ppv.videosCount}</ins>
                                </span>
                            </li>

                        </ul>
                    </div>
                </Link>
            </div>
        )
    }

    const renderTipMessage = () => {
        return (
            <div className="chatContent tipMsg">
                <div className="itemTitle">

                   Napiwek od: {props.data.sender.displayName}
                    <br></br>
                    {data.reply} <br></br>
                    <i class="fa fa-money" aria-hidden="true"></i><br></br>
                </div>
            </div>
        )
    }
    const renderContent = () => {
        if (data.type === 'ppvShare') {
            return (renderPPVShare())
        }
        if (data.type === 'newTip') {
            return (renderTipMessage())
        }
        return (renderMessageContent())
    }

//console.log(data.reply)
    return (
        <>
            <li className={liClass}>
                <figure><img src={data.sender.avatar} alt="" /></figure>
              
                <div className="text-box">
                    {renderContent()}

                    <span>{/*<i className="ti-check"></i><i className="ti-check"></i>*/} {data.formatedTime}</span>
                    {me &&
                        <span className="share" onClick={() => { props.shareMessage(data) }}>
                            <i class="fa fa-share-alt"></i>
                        </span>
                    }
                </div>
            </li>
        </>

    )
}

export default ChatMessageItem