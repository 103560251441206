
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { getUsersCount, postAlerts, sendMassMail } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import parse, { domToReact } from 'html-react-parser';
import toast, { Toaster } from 'react-hot-toast';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsSearch } from "react-icons/bs";
import DashboardBox from "../components/dashboardBox";
import DialogToast from "../components/dialogToast";
import { BsClock } from 'react-icons/bs';
import { Link } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { genders } from '../constants/common'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class AlertsView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //   this.props.getVerificationList(this.dataFetched)

    }

    componentWillUnmount() {
    }

    state = {
        //view: 'mails',
        view: 'alerts',
        sampleAlertShow: true,
        buttonText: 'close',
        variant: 'success',
        usersCount: 0,
        alertTitle: '',
        alertBody: '',
        hideForm: false,
        deleteOnReaded: true,
        fans: false,
        creators: false,
        pendingCreators: false,
        genders: [],
        mailingMode:'bcc',
        test:true,

    }
    resetForms = () => {
        toast.dismiss()
        this.setState({
            fans: false,
            creators: false,
            pendingCreators: false,
            sampleAlertShow: true,
            buttonText: 'close',
            variant: 'success',
            usersCount: 0,
            alertTitle: '',
            alertBody: '',
            deleteOnReaded: true,
            hideForm: false,
            genders: [],
            mailingMode:'bcc',
        })
        for (const [key, value] of Object.entries(genders)) {
            console.log('genderChecked' + key)
            this.setState({ ['genderChecked' + key]: false }, () => {
                console.log('state', this.state)
            })
        }
    }

    parseWithLinks = (text) => {
        const options = {
            replace: ({ name, attribs, children }) => {
                if (name === 'a' && attribs.href) {
                    return <Link to={attribs.href}>{domToReact(children)}</Link>;
                }
            }
        };

        return parse(text, options);
    }

    dataFetched = (data) => {
        console.log('dataFetched', data)
        if (!data.error) {
            this.setState({ data: data })
        }

    }

    fetchUserSearch = () => {
        this.setState({ fetching: true })
        this.setState({ usersCountFetching: true })
        const fd = new FormData()
        let filters = {}
        if (this.state.fans) {
            fd.append('fans', true)
        }
        if (this.state.creators) {
            fd.append('creators', true)
        }
        if (this.state.pendingCreators) {
            fd.append('pendingCreators', true)
        }
        fd.append('genders[]', JSON.stringify(this.state.genders))
        getUsersCount(fd, this.getUsersCountFetched)
    }

    getUsersCountFetched = (data) => {
        this.setState({ fetching: false })
        this.setState({ usersCountFetching: false })
        console.log('getUsersCountFetched', data)
        if (!data.error) {
            this.setState({ usersCount: data.count })
        }

    }


    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }


    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };



    sendAlerts = (confirmed = false) => {
        toast.dismiss();
        if (!this.state.alertTitle) {
            toast.error((t) => { return this.renderToastContent(t, 'Musisz wpisać tytuł alertu!') })
            return
        }
        if (!this.state.alertBody) {
            toast.error((t) => { return this.renderToastContent(t, 'Musisz wpisać treść alertu!') })
            return
        }
        if (!this.state.fans && !this.state.creators && !this.state.pendingCreators) {
            toast.error((t) => { return this.renderToastContent(t, 'Musisz Wybrać grupę użytkowników!') })
            return
        }
        if (!confirmed) {
            DialogToast('Czy wysłać ten alert do ' + this.state.usersCount + ' użytkowników?', () => this.sendAlerts(true))
            return
        }
        let loaderToast = toast.loading('Wysyłam alerty do ' + this.state.usersCount + ' użytkowników...');
        this.setState({ loaderToast: loaderToast, fetching: true })
        let filters = {}
        if (this.state.fans) {
            filters.fans = true
        }
        if (this.state.creators) {
            filters.creators = true
        }
        if (this.state.pendingCreators) {
            filters.pendingCreators = true
        }
        for (const [key, value] of Object.entries(genders)) {

        }
        filters.deleteOnReaded = this.state.deleteOnReaded
        // filters.genders=this.state.genders
        //   fd.append('genders[]', JSON.stringify(this.state.genders))
        const fd = new FormData()
        //  fd.append('filters', JSON.stringify(filters))
        if (this.state.fans) {
            fd.append('fans', true)
        }
        if (this.state.creators) {
            fd.append('creators', true)
        }
        if (this.state.pendingCreators) {
            fd.append('pendingCreators', true)
        }
        if (this.state.deleteOnReaded) {
            fd.append('deleteOnReaded', true)
        }


        fd.append('genders[]', JSON.stringify(this.state.genders))
        fd.append('title', this.state.alertTitle)
        fd.append('body', this.state.alertBody)
        fd.append('variant', this.state.variant)
        fd.append('buttonText', this.state.buttonText)
        postAlerts(fd, this.alertsSent)
    }

    alertsSent = (data) => {
        this.setState({ fetching: false })
        toast.dismiss();
        if (data.error) {
            toast.error((t) => { return this.renderToastContent(t, data.message) })
            return
        }
        else {
            this.setState({ hideForm: true })
            toast.success((t) => { return this.renderToastContent(t, data.message) })
        }
    }

    sendMails = (confirmed = false) => {
        toast.dismiss();
      //  let mailContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        let mailContent=this.state.mailContent
        if (!this.state.alertTitle) {
            toast.error((t) => { return this.renderToastContent(t, 'Musisz wpisać tytuł maila!') })
            return
        }
        if (!mailContent) {
            toast.error((t) => { return this.renderToastContent(t, 'Musisz wpisać treść maila!') })
            return
        }
        if (!this.state.test&&!this.state.fans && !this.state.creators && !this.state.pendingCreators) {
            toast.error((t) => { return this.renderToastContent(t, 'Musisz Wybrać grupę użytkowników!') })
            return
        }
        if (!confirmed) {
            if(!this.state.test){
                DialogToast('Czy wysłać tego maila do ' + this.state.usersCount + ' użytkowników?', () => this.sendMails(true))
            }else{
                DialogToast('Czy wysłać testowego maila do siebie?', () => this.sendMails(true))
            }
     
            return
        }
        let loaderToast = toast.loading('Wysyłam maile do ' + this.state.usersCount + ' użytkowników...');
        this.setState({ loaderToast: loaderToast, fetching: true })
        let filters = {}
        if (this.state.fans) {
            filters.fans = true
        }
        if (this.state.creators) {
            filters.creators = true
        }
        if (this.state.pendingCreators) {
            filters.pendingCreators = true
        }
        // filters.genders=this.state.genders
        //   fd.append('genders[]', JSON.stringify(this.state.genders))
        const fd = new FormData()
        //  fd.append('filters', JSON.stringify(filters))
        if (this.state.fans) {
            fd.append('fans', true)
        }
        if (this.state.creators) {
            fd.append('creators', true)
        }
        if (this.state.pendingCreators) {
            fd.append('pendingCreators', true)
        }
        if (this.state.deleteOnReaded) {
            fd.append('deleteOnReaded', true)
        }
        if (this.state.test) {
            fd.append('test', true)
        }
        fd.append('mailingMode', this.state.mailingMode)
        

        fd.append('genders[]', JSON.stringify(this.state.genders))
        fd.append('title', this.state.alertTitle)
        //fd.append('body', this.state.alertBody)
        if(this.state.mailHeaderText){
            fd.append('headerText', this.state.mailHeaderText)
        }
        fd.append('body', mailContent)

        fd.append('variant', this.state.variant)
        fd.append('buttonText', this.state.buttonText)
        console.log('wysylam')
        this.props.sendMassMail(fd, this.mailsSent)
    }

    mailsSent = (data) => {
        this.setState({ fetching: false })
        toast.dismiss();
        if (data.error) {
            toast.error((t) => { return this.renderToastContent(t, data.message) })
            return
        }
        else {
            this.setState({ hideForm: true })
            toast.success((t) => { return this.renderToastContent(t, data.message) })
        }
    }

    fakeAlertClose = () => {
        toast.dismiss()
        toast.error('Podgladu alertu nie mozna zamknąć.', { duration: 1000 })
    }


    handleTitleInputOnChange = (e) => {
        this.setState({ alertTitle: e.target.value })
    }
    handleBodyInputOnChange = (e) => {
        this.setState({ alertBody: e.target.value })
    }


    handleMailHeaderTextInputOnChange=(e)=>{
        this.setState({ mailHeaderText: e.target.value })
    }
    handleMailInputOnChange=(e)=>{
        this.setState({ mailContent: e.target.value })
        
    }

    handleUsersFilterChange = (e) => {
        console.log('handleUsersFilterChange', e.target.name, e.target.checked)
        this.setState({ [e.target.name]: e.target.checked }, () => {
            this.fetchUserSearch()
        })
    }

    handleGenderFilterChange = (e, key, value) => {
        console.log('typeof', typeof (parseInt(key)))
        let gendersState = JSON.parse(JSON.stringify(this.state.genders))
        let index = gendersState.findIndex(x => x === parseInt(key));
        if (index != -1) {
            gendersState.splice(index, 1)
        }
        if (e.target.checked === true) {
            gendersState.push(parseInt(key))
        }
        this.setState({ genders: gendersState, ['genderChecked' + key]: e.target.checked },
            () => {
                // console.log(this.state)
                this.fetchUserSearch()
            }

        )
    }

    renderGenderFilters = () => {
        let checks = []
        for (const [key, value] of Object.entries(genders)) {
            checks.push(
                <Form.Check
                    key={'genderCheck' + key}
                    inline
                    label={value}
                    name={value}
                    value={this.state['genderChecked' + key]}
                    type={'checkbox'}
                    onChange={(e) => this.handleGenderFilterChange(e, key, value)}
                    id={'henderCheck' + key}
                    checked={this.state['genderChecked' + key] === true ? true : false}
                    disabled={this.state.fetching}
                />
            )
        }
        return (
            checks.map((item, index) => {
                return item
            })
        )
    }

    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        let buttonText = this.state.buttonText
        if (this.state.buttonText) {
            if (this.state.buttonText === 'consent') {
                buttonText = 'Consent'
            }
            if (this.state.buttonText === 'close') {
                buttonText = 'Close'
            }
            if (this.state.buttonText.toLowerCase() === 'ok') {
                buttonText = 'Ok'
            }
        }
        let variant = this.state.variant
        let buttonVariant = ''
        if (this.state.variant) {
            variant = this.state.variant
            if (variant === 'primary') {
                buttonVariant = 'outline-primary'
            }
            if (variant === 'secondary') {
                buttonVariant = 'outline-secondary'
            }
            if (variant === 'success') {
                buttonVariant = 'outline-success'
            }
            if (variant === 'danger') {
                buttonVariant = 'outline-danger'
            }
            if (variant === 'warning') {
                buttonVariant = 'outline-warning'
            }
            if (variant === 'info') {
                buttonVariant = 'outline-info'
            }
            if (variant === 'dark') {
                buttonVariant = 'outline-dark'
            }
            if (variant === 'light') {
                buttonVariant = 'outline-light'
            }
        }
        return (
            <Col xs={12} className='p-0'>
                <Toaster />

                <Row noGutters>
                    <Col xs={12} className='text-center upperCase pb-1'>
                        <h3>ALERTY - MAILING</h3>
                    </Col>
                </Row>

                <Row noGutters>
                    <Col xs={12} className='p-2 border'>
                        <Row noGutters>
                            <Col xs={12} className='p-2 border text-center'>
                                <Form.Check
                                    inline
                                    label="Fani"
                                    name="fans"
                                    value={this.state.fans}
                                    type={'checkbox'}
                                    onChange={this.handleUsersFilterChange.bind(this)}
                                    id={'formuaCheck1'}
                                    checked={this.state.fans}
                                    disabled={this.state.fetching}
                                />
                                <Form.Check
                                    inline
                                    label="Twórcy"
                                    name="creators"
                                    value={this.state.creators}
                                    type={'checkbox'}
                                    onChange={this.handleUsersFilterChange.bind(this)}
                                    id={'formuaCheck2'}
                                    checked={this.state.creators}
                                    disabled={this.state.fetching}
                                />
                                <Form.Check
                                    inline
                                    label="Oczekujacy na weryfikacje"
                                    name="pendingCreators"
                                    value={this.state.pendingCreators}
                                    type={'checkbox'}
                                    onChange={this.handleUsersFilterChange.bind(this)}
                                    id={'formuaCheck3'}
                                    checked={this.state.pendingCreators}
                                    disabled={this.state.fetching}
                                />
                                lang: pl/en
                                <Row noGutters>
                                    <Col xs={12} className='p-2 text-center capitalize'>
                                        {this.renderGenderFilters()}
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={12} className='py-2 text-center '>
                                        {this.state.usersCountFetching &&
                                            <div style={{ zIndex: '100', backgroundColor: 'rgba(255,255,255,0.4)', position: 'absolute', left: '0px', right: '0px', top: '0px', bottom: '0px' }}>
                                                <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                                                    <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={25} width={25} />
                                                </Container>
                                            </div>

                                        }
                                        <strong>
                                            Wybrano: {this.state.usersCount} użytkowników.
                                        </strong>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col xs={12} className="text-center">
                                <Form.Check
                                    inline
                                    label="Alerty"
                                    name="view"
                                    value={'view'}
                                    type={'radio'}
                                    onChange={() => this.setState({ view: 'alerts' })}
                                    id={'viewRadio1'}
                                    checked={this.state.view === 'alerts' ? true : false}
                                    disabled={this.state.fetching}
                                />
                                <Form.Check
                                    inline
                                    label="Mailing"
                                    name="view"
                                    value={'view'}
                                    type={'radio'}
                                    onChange={() => this.setState({ view: 'mails' })}
                                    id={'viewRadio2'}
                                    checked={this.state.view === 'mails' ? true : false}
                                    disabled={this.state.fetching}
                                />
                            </Col>
                        </Row>
                        {this.state.view === 'alerts' &&
                            <Row noGutters>
                                <Col xs={12} className='pt-2 text-center'>
                                    <h3>WYŚLIJ ALERTY</h3>
                                </Col>
                                <Col xs={12} className='pt-2 text-center'>
                                    <Alert show={this.state.sampleAlertShow}
                                        variant={variant}
                                    >
                                        <Alert.Heading>
                                            {this.state.alertTitle ? this.state.alertTitle : 'Dodaj tytuł'}
                                        </Alert.Heading>
                                        <p >
                                            {this.parseWithLinks(this.state.alertBody ? this.state.alertBody : 'Dodaj treść')}
                                        </p>
                                        <hr />
                                        <div className="d-flex justify-content-end">
                                            <Button variant={buttonVariant} onClick={() => { this.fakeAlertClose() }}>
                                                {this.state.buttonText}
                                            </Button>
                                        </div>
                                    </Alert>
                                </Col>
                                <Col xs={12} className=' text-center'>
                                    <Form.Check
                                        inline
                                        label="Info"
                                        name="variant"
                                        value={'success'}
                                        type={'radio'}
                                        onChange={() => this.setState({ variant: 'success' })}
                                        id={'variantRadio1'}
                                        checked={this.state.variant === 'success' ? true : false}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label="Ostrzeżenie"
                                        name="variant"
                                        value={'warning'}
                                        type={'radio'}
                                        onChange={() => this.setState({ variant: 'warning' })}
                                        id={'variantRadio2'}
                                        checked={this.state.variant === 'warning' ? true : false}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label="Bolokada"
                                        name="variant"
                                        value={'danger'}
                                        type={'radio'}
                                        onChange={() => this.setState({ variant: 'danger' })}
                                        id={'variantRadio3'}
                                        checked={this.state.variant === 'danger' ? true : false}
                                        disabled={this.state.fetching}
                                    />
                                </Col>
                            </Row>
                        }
                        {this.state.view === 'mails' &&
                            <Row noGutters>
                                <Col xs={12} className='pt-2 text-center'>
                                    <h3>WYŚLIJ MAILE</h3>
                                </Col>
                                <Col xs={12} className='py-2  text-center border'>
                                    {this.state.alertTitle ? this.state.alertTitle : 'Dodaj tytuł'}
                                </Col>
                                <Col xs={12} className=' py-4 text-center border'>
                                    {this.parseWithLinks(this.state.alertBody ? this.state.alertBody : 'Dodaj treść')}
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
                {!this.state.hideForm && this.state.view === 'mails' &&
                    <Row noGutters>
                        <Col xs={12} className='mx-auto text-center mt-3 '>
                            <Form.Check
                                        inline
                                        label="BCC"
                                        name="variant"
                                        value={'success'}
                                        type={'radio'}
                                        onChange={() => this.setState({ mailingMode: 'bcc' })}
                                        id={'variantRadio1'}
                                        checked={this.state.mailingMode === 'bcc' ? true : false}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label="Indywidualnie"
                                        name="variant"
                                        value={'warning'}
                                        type={'radio'}
                                        onChange={() => this.setState({ mailingMode: 'oneByOne' })}
                                        id={'variantRadio2'}
                                        checked={this.state.mailingMode === 'oneByOne' ? true : false}
                                        disabled={this.state.fetching}
                                    />
                                     <Form.Check
                                        inline
                                        label="Test"
                                        name="variant"
                                        value={'warning'}
                                        type={'checkbox'}
                                        onChange={(e) => this.setState({ test: e.target.checked})}
                                        id={'variantRadio2'}
                                        checked={this.state.test}
                                        disabled={this.state.fetching}
                                    />
                        </Col>
                        {this.state.mailingMode === 'oneByOne' &&
                        <Col xs={12} className='mx-auto text-center mt-3 '>
                                Użyj tagu {"{displayName}"} aby wstawic nazwę użytkownika
                            </Col>
                    }
                    </Row>
                }
                {!this.state.hideForm &&
                    <Row noGutters>
                        <Col xs={11} md={6} className='mx-auto pt-2  fadeIn  '>
                            <Form.Control
                                type="text"
                                placeholder="Tytuł"
                                onChange={(e) => this.handleTitleInputOnChange(e)}
                                onPaste={(e) => this.handleTitleInputOnChange(e)}
                                value={this.state.alertTitle}
                                disabled={this.state.fetching}
                            // disabled={this.state.searchFetching}
                            />
                        </Col>
                    </Row>
                }
                {!this.state.hideForm && this.state.view !== 'mails' &&
                    <Row noGutters>
                        <Col xs={11} md={6} className='mx-auto py-2  fadeIn  '>
                            <Form.Control
                                type="textarea"
                                as="textarea"
                                placeholder="Treść"
                                onChange={(e) => this.handleBodyInputOnChange(e)}
                                onPaste={(e) => this.handleBodyInputOnChange(e)}
                                value={this.state.alertBody}
                                style={{ height: '100px' }}
                                disabled={this.state.fetching}
                            // disabled={this.state.searchFetching}
                            />
                        </Col>
                    </Row>
                }

                {!this.state.hideForm && this.state.view === 'mails' &&
                    <Row noGutters>

                        <Col xs={11} md={6} className='mx-auto text-left mt-3 ' style={{ minHeight: '300px' }}>
                        <strong>Header:</strong>
                            <Form.Control
                                type="text"
                                placeholder="Wytłuszczony nagłówek w treści maila"
                                onChange={(e) => this.handleMailHeaderTextInputOnChange(e)}
                                onPaste={(e) => this.handleMailHeaderTextInputOnChange(e)}
                                value={this.state.mailHeaderText}
                                disabled={this.state.fetching}
                            // disabled={this.state.searchFetching}
                            />
                            <strong>Treść:</strong>
                            <Form.Control
                                type="textarea"
                                as="textarea"
                                placeholder="Treść"
                                onChange={(e) => this.handleMailInputOnChange(e)}
                                onPaste={(e) => this.handleMailInputOnChange(e)}
                                value={this.state.mailContent}
                                style={{ height: '100px' }}
                                disabled={this.state.fetching}
                            // disabled={this.state.searchFetching}
                            />
                            {/**
                            <Editor
                                editorState={this.state.editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="px-2 border"
                                onEditorStateChange={this.onEditorStateChange}
                            /> 
                            */}
                        </Col>
                    </Row>
                }

                {!this.state.hideForm && this.state.view === 'alerts' &&
                    <Row noGutters>
                        <Col xs={11} md={6} className='mx-auto py-2 text-left fadeIn  '>
                            <Form.Check
                                inline
                                label="Usunąć po przeczytaniu"
                                name="deleteOnReaded"
                                value={this.state.deleteOnReaded}
                                type={'checkbox'}
                                onChange={this.handleUsersFilterChange.bind(this)}
                                id={'deleteOnReadedCheck'}
                                checked={this.state.deleteOnReaded}
                                disabled={this.state.fetching}
                            /><br></br>
                            <small className="text-secondary">
                                Czy alert ma zostać usunięty z bazy po przeczytaniu przez użytkownika?
                            </small>

                        </Col>
                    </Row>
                }

                {!this.state.hideForm && this.state.view === 'alerts' &&
                    <Row noGutters>
                        <Col xs={10} md={3} className='mx-auto text-center pt-2 pb-3  fadeIn  '>
                            <Button
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                    this.sendAlerts()
                                }}
                                disabled={this.state.fetching}
                            >
                                WYŚLIJ ALERTY
                            </Button>
                        </Col>
                    </Row>
                }
                {!this.state.hideForm && this.state.view === 'mails' &&
                    <Row noGutters>
                        <Col xs={10} md={3} className='mx-auto text-center pt-2 pb-3  fadeIn  '>
                            <Button
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                    this.sendMails()
                                }}
                                disabled={this.state.fetching}
                            >
                                WYŚLIJ MAILE
                            </Button>
                        </Col>
                    </Row>
                }
                {this.state.hideForm &&
                    <Row noGutters>
                        <Col xs={10} md={3} className='mx-auto text-center pt-2 pb-3  fadeIn  '>
                            <Button
                                variant="danger"
                                size="sm"
                                onClick={() => { this.resetForms() }}
                                disabled={this.state.fetching}
                            >
                                WYŚLIJ KOLEJNY
                            </Button>
                        </Col>
                    </Row>
                }
            </Col>
        )
    }

}



function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getUsersCount,
                sendMassMail
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertsView);

