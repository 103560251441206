import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'



  export const getUserSearchList=(fd, callBack) =>{
    let path=ApiUrl+"userssearch/"
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
      //  console.log('getUserSearchList response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        // handle error
   //     console.log('error', error)
        callBack({' getUserSearchList error':true, data:error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }
