import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const getPayoutDetails=(payoutId, callBack) =>{
  let path=ApiUrl+"payoutsdetails/"+payoutId+"/"
  console.log('getPayoutDetails path', path)
    axiosClient.get(path)
    .then( (response)=> {
      console.log('getPayoutDetails response', response.data)
      callBack(response.data)
    })
    .catch( (error)=> {
      console.log('getPayoutDetails error', error)
      callBack({'error':true, 'data':error.response.data})
    })
    .then(()=> {
      // always executed
    });
}
export const editPayoutDetails=(payoutId, fd, callBack) =>{
  let path=ApiUrl+"payoutsdetails/"+payoutId+"/"
  console.log('editPayoutDetails path', path)
    axiosClient.patch(path, fd)
    .then( (response)=> {
      console.log('editPayoutDetails response', response.data)
      callBack(response.data)
    })
    .catch( (error)=> {
      console.log('editPayoutDetails error', error)
      callBack({'error':true, 'data':error.response.data})
    })
    .then(()=> {
      // always executed
    });
}

export const getPayoutsList=(fd, callBack) =>{
  let path=ApiUrl+"payoutslist/"
  console.log('getPayouts path', path)
    axiosClient.post(path, fd)
    .then( (response)=> {
      console.log('getPayoutsList response', response.data)
      callBack(response.data)
    })
    .catch( (error)=> {
      console.log('getPayoutsList error', error)
      callBack({'error':true, 'data':error.response.data})
    })
    .then(()=> {
      // always executed
    });
}

export const generatePayouts=(save, callBack) =>{
  let path=ApiUrl+"generatepayouts/"
  if(save){
      path=path+'save/'
  }
  console.log('generatePayouts path', path)
    axiosClient.get(path)
    .then( (response)=> {
      console.log('generatePayouts response', response.data)
      callBack(response.data)
    })
    .catch( (error)=> {
      console.log('generatePayouts error', error)
      callBack({'error':true, 'data':error.response.data})
    })
    .then(()=> {
      // always executed
    });
}
//SPRAWDZIC CZY UZYWANE?
export const getPayouts=(save, callBack) =>{
    let path=ApiUrl+"payouts/"
    if(save){
        path=path+'save/'
    }
    console.log('getPayouts path', path)
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getPayouts response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getPayouts error', error)
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
      });
  }

  export const getInvoicePdf=(payoutId, callBack) =>{
    let path=ApiUrl+"invoicepdf/"+payoutId+"/"
    console.log('getInvoicePdf path', path)
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getInvoicePdf response', response.data)
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
         const pdfWindow = window.open();
         pdfWindow.location.href = fileURL; 
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getInvoicePdf error', error)
        callBack({'error':error.response.data})
      })
      .then(()=> {
        // always executed
      });
  }