import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const getUser=(id, callBack) =>{
    let path=ApiUrl+"user/"+id+"/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        //console.log('getUser',response.data)
        dispatch({ type: "SET_USER_DATA", data: response.data.user});
        dispatch({ type: "SET_PROFILE_DATA", data: response.data.profile});
        dispatch({ type: "SET_ADMIN_LOGS_DATA", data: response.data.adminLogs});
        dispatch({ type: "SET_IN_WATCHED", data: response.data.inWatched});
        dispatch({ type: "SET_DECLINE_REASONS", data: response.data.declineReasons});
        dispatch({ type: "SET_NEXT_ACTIONS", data: response.data.nextActions});
        dispatch({ type: "SET_IN_SUSPECTED", data: response.data.inSuspected});
        dispatch({ type: "SET_ADMIN_NOTES", data: response.data.adminNotes});
        
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const updateProfile=(id, fd, callBack) =>{
    let path=ApiUrl+"profile/"+id+"/"
    return dispatch =>{
      axiosClient.patch(path, fd)
      .then( (response)=> {
        callBack(response.data)
        dispatch({ type: "SET_PROFILE_DATA", data: response.data});
      })
      .catch( (error)=> {
       
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const getWatchedList=(callBack) =>{
    let path=ApiUrl+"watched/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const addToWatched=(id, callBack) =>{
    let path=ApiUrl+"watched/"+id+"/"
    return dispatch =>{
      axiosClient.post(path)
      .then( (response)=> {
        callBack(response.data)
        dispatch({ type: "SET_IN_WATCHED", data: response.data.inWatched});
      })
      .catch( (error)=> {
       
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const removeFromWatched=(id, callBack) =>{
    let path=ApiUrl+"watched/"+id+"/"
    return dispatch =>{
      axiosClient.delete(path)
      .then( (response)=> {
        callBack(response.data)
        dispatch({ type: "SET_IN_WATCHED", data: response.data.inWatched});
      })
      .catch( (error)=> {
        
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }
  export const addToSuspected=(id, fd, callBack) =>{
    let path=ApiUrl+"suspected/"+id+"/"
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
        callBack(response.data)
        dispatch({ type: "SET_IN_SUSPECTED", data: response.data});
      })
      .catch( (error)=> {
       
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }
  export const removeFromSuspected=(id, callBack) =>{
    let path=ApiUrl+"suspected/"+id+"/"
    return dispatch =>{
      axiosClient.delete(path)
      .then( (response)=> {
        callBack(response.data)
        dispatch({ type: "SET_IN_SUSPECTED", data: response.data});
      })
      .catch( (error)=> {
       
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const addAdminNotes=(id, fd, callBack) =>{
    let path=ApiUrl+"adminnotes/"+id+"/"
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
        callBack(response.data)
        dispatch({ type: "SET_ADMIN_NOTES", data: response.data});
      })
      .catch( (error)=> {
       
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const getMailTemplates=(callBack) =>{
    let path=ApiUrl+"mailtemplates/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getMailTemplates',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
//console.log('getMailTemplateserror ', error)
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }
  
  export const getAdminMailsList=(callBack) =>{
    let path=ApiUrl+"mail/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getAdminMailsList',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
       // console.log('getMailTemplateserror ', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
  
      });
    }
  }
  
  export const sendMail=(id, fd,callBack) =>{
    let path=ApiUrl+"mail/"+id+"/"
    return dispatch =>{
      axiosClient.post(path,fd)
      .then( (response)=> {
        console.log('getMailTemplates',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        //console.log('getMailTemplateserror ', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
    }
  }
  
  export const getPayoutData=(id, callBack) =>{
    let path=ApiUrl+"payoutdata/"+id+"/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
       // console.log('getMailTemplates',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        //console.log('getMailTemplateserror ', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
    }
  }

  export const getKupione=(id, page, callBack) =>{
    let path=ApiUrl+"kupione/"+id+"/?page="+page
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
       // console.log('getMailTemplates',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        //console.log('getMailTemplateserror ', error)
        callBack({error:true, data:error.response.data})
      })
      .then(()=> {
        // always executed
      });
    }
  }
  
  export const getSprzedane=(id, page, callBack) =>{
    let path=ApiUrl+"sprzedane/"+id+"/?page="+page
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
       // console.log('getMailTemplates',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        //console.log('getMailTemplateserror ', error)
        callBack({error:true, data:error.response.data})
      })
      .then(()=> {
        // always executed
      });
    }
  }
  export const getSprzedaneSummary=(id, callBack) =>{
    let path=ApiUrl+"sprzedanesummary/"+id+"/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
       // console.log('getMailTemplates',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        //console.log('getMailTemplateserror ', error)
        callBack({error:true, data:error.response.data})
      })
      .then(()=> {
        // always executed
      });
    }
  }
  
  export const getFansSummary=(id, callBack) =>{
    let path=ApiUrl+"fanssummary/"+id+"/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
       // console.log('getMailTemplates',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        //console.log('getMailTemplateserror ', error)
        callBack({error:true, data:error.response.data})
      })
      .then(()=> {
        // always executed
      });
    }
  }


  export const getUserPayoutsList=(id, page, callBack) =>{
    let path=ApiUrl+"userpayoutslist/"+id+"/?page="+page
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getUserPayoutsList',response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getUserPayoutsList ', error)
        callBack({error:true, data:error.response.data})
      })
      .then(()=> {
        // always executed
      });
    }
  }

  