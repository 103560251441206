import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const getChatBlogers=(callBack, page, search) =>{
    let path=ApiUrl+"chat/bloggerslist/?page="+page
    if(search){
      path=path+"&search="+search
    }
    console.log('getChatBlogers path', path)
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        //console.log('getUser',response.data)
      //  dispatch({ type: "SET_USER_DATA", data: response.data.user});        
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const getUserChatList=(uid, callBack, page) =>{
    let path=ApiUrl+"chat/userchats/"+uid+"/?page="+page
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        //console.log('getUser',response.data)
      //  dispatch({ type: "SET_USER_DATA", data: response.data.user});        
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }

  export const getChatDetails=(chatId, page, callBack) =>{
    let path=ApiUrl+"chat/details/"+chatId+"/?page="+page
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        //console.log('getUser',response.data)
      //  dispatch({ type: "SET_USER_DATA", data: response.data.user});        
        callBack(response.data)
      })
      .catch( (error)=> {
        callBack({'error':true, 'data':error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }
  export const banChatMessage=(messageId, fd, callBack) =>{
    let path=ApiUrl+"chat/message/"+messageId+"/"
    return dispatch =>{
      axiosClient.patch(path, fd)
      .then( (response)=> {
        //console.log('getUser',response.data)
      //  dispatch({ type: "SET_USER_DATA", data: response.data.user});        
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('error', error)
        if(error.response){
          callBack({'error':true, 'data':error.response.data})
        }
        
      })
      .then(()=> {
        // always executed
  
      });
    }
  }