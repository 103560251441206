import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const getPromoCommisionRequests=(filter, callBack) =>{
    let path=ApiUrl+"promocommissionrequests/"+filter+"/"
    console.log(path)
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        //console.log('userSearch response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        // handle error
        callBack({'error':true, data:error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }
 
  export const patchPromoCommisionRequests=(fd, callBack) =>{
    let path=ApiUrl+"promocommissionrequests/"
    return dispatch =>{
      axiosClient.patch(path, fd)
      .then( (response)=> {
        //console.log('userSearch response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        // handle error
        callBack({'error':true, data:error.response.data})
      })
      .then(()=> {
        // always executed
  
      });
    }
  }