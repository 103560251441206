import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    addToSuspected,
    removeFromSuspected,
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import toast, { Toaster } from 'react-hot-toast';
import DialogToast from "../dialogToast";

class InSuspectedBox extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
        this.setState({ checked: this.props.inSuspected.status, reason:this.props.inSuspected.reason })
    }
    state = {
        fetching: false,

    }

    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }
    handleRadio = (checked) => {
        if (!checked) {
            DialogToast('Czy napewno chcesz zdjąć profil z listy podejrzanych?', () => {
                this.setState({ checked: checked, reason:'' }, () => {
                    this.saveForm()
                })
            })
            return
        }
        this.setState({ checked: checked })

    }

    saveForm = () => {
        
        toast.dismiss()
        if(this.state.checked){
            if(this.state.reason.length===0){
                toast.error((t) => { return this.renderToastContent(t, 'Musisz podać powód podejrzeń!') })
                return
            }
            this.setState({ fetching: true })
            const fd= new FormData()
            fd.append('reason', this.state.reason)
            this.props.addToSuspected(this.props.user.id, fd, this.formSaved)
        }
        else{
            this.setState({ fetching: true })
            this.props.removeFromSuspected(this.props.user.id, this.formSaved)
        }
    }

    formSaved = (data) => {
        this.setState({ fetching: false })
        if (!data.error) {
            toast.success((t) => { return this.renderToastContent(t, data.message) })
        }
        else {
            let msg=''
            if(data.data.message){
                msg=data.data.message
            }
            else{
                msg=data.data
            }
            toast.error((t) => { return this.renderToastContent(t, msg) })
        }
    }

    render() {
        return (
            <Row noGutters>
                <Col xs={12} className='border p-3'>
                    <Toaster />
                    <Row noGutters>
                        <Col xs={12} className='text-center border p-3'>

                            <Row noGutters>
                                <Col xs={12} className='text-center pb-2'>
                                    <span style={{ marginRight: '15px' }}>
                                        Profil podejrzany:
                                    </span>
                                    <Form.Check
                                        inline
                                        label={'Tak'}
                                        name={'inSuspected'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => this.handleRadio(true)}
                                        id={'inWatchedRadio'}
                                        checked={this.state.checked}
                                        disabled={this.state.fetching}
                                    />
                                    <Form.Check
                                        inline
                                        label={'Nie'}
                                        name={'inSuspected'}
                                        value={true}
                                        type={'radio'}
                                        onChange={(e) => this.handleRadio(false)}
                                        id={'inWatchedRadio'}
                                        checked={!this.state.checked}
                                        disabled={this.state.fetching}
                                    />

                                </Col>
                            </Row>
                            {this.state.checked &&
                                <Row noGutters>
                                    <Col xs={10} md={6} className='mx-auto text-center pb-3'>
                                        <Form.Control
                                            as="textarea"
                                            type="textarea"
                                            placeholder="Podaj powód podejrzeń"
                                            value={this.state.reason}
                                            onChange={(e)=>{this.setState({reason:e.target.value})}}
                                            disabled={this.state.fetching}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <Row noGutters>
                                            <Col xs={10} md={3} className='mx-auto text-center pb-3'>
                                                {this.props.inSuspected.reason!==this.state.reason&&
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() => { this.saveForm() }}
                                                        disabled={this.state.fetching}
                                                    >
                                                        ZAPISZ ZMIANY
                                                    </Button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
        // inWatched:state.user.inWatched,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                addToSuspected,
                removeFromSuspected,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InSuspectedBox);