
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { userSearch } from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { BsSearch } from "react-icons/bs";

class UserSearchBox extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.props.getFavorites(this.favoritesFetched)

  }

  componentWillUnmount() {
  }

  state = {
    emailInput: '',
    displayNameInput: '',
    idInput: '',
    timeOut: null,
    formula: 'startswith',
    gender: 'all',
    isVerified: 'all'
  }


  fetchSearch = () => {
    let data;
    let filter = 'all'
    if (this.state.displayNameInput.length > 0) {
      data = this.state.displayNameInput
      filter = 'displayName'
    }
    else if (this.state.idInput.length > 0) {
      data = this.state.idInput
      filter = 'id'
    }
    else if (this.state.emailInput.length > 0) {
      data = this.state.emailInput
      filter = 'email'
    }
    //else {
    //  this.setState({ searchFetching: false, data: undefined })
   //   return
   // }
   // if (!data) {
   //   this.setState({ searchFetching: false, data: undefined })
   //   return
   // }
    this.setState({ searchFetching: true })
    let isVerified = this.state.isVerified
    if (this.props.isVerified) {
      isVerified = this.props.isVerified
    }
    let gender = this.state.gender
    if (this.props.gender) {
      gender = this.props.gender
    }
    console.log('isVerified', isVerified)
    const fd = new FormData()
    fd.append('data', data)
    fd.append('filter', filter)
    fd.append('formula', this.state.formula,)
    fd.append('isVerified', isVerified)
    fd.append('gender', gender)
    this.props.userSearch(fd, this.searchFetched)
  }




  searchFetched = (data) => {
    console.log('searchFetched', data)
    this.setState({ searchFetching: false })
    if (!data.error) {
      this.setState({ data: data })
    }
  }
  handleEmailInputOnChange = (e) => {
    this.setState({ displayNameInput: '', idInput: '' })
    this.setState({ emailInput: e.target.value })

  }

  handleDisplayNameInputOnChange = (e) => {
    this.setState({ searchFetching: true })
    this.setState({ emailInput: '', idInput: '' })
    this.setState({ displayNameInput: e.target.value })
    clearTimeout(this.state.timeOut);
    this.setState({ timeOut: setTimeout(() => this.fetchSearch(), 500) })


  }

  handleIdInputOnChange = (e) => {
    this.setState({ searchFetching: true })
    this.setState({ emailInput: '', displayNameInput: '' })
    this.setState({ idInput: e.target.value })
    clearTimeout(this.state.timeOut);
    this.setState({ timeOut: setTimeout(() => this.fetchSearch(), 500) })
  }

  renderResults = () => {
    if (!this.props.onClick) {
      return (
        this.state.data.map((item, index) => {
          return (
            <Col xs={12} key={'sr' + index}>
              <Link to={'/user/' + item.user_id + '/'} target={'_blank'}>
                {item.displayName}
              </Link>
            </Col>
          )
        })
      )
    }
    else {
      return (
        this.state.data.map((item, index) => {
          return (
            <Col xs={12} key={'sr' + index} className='pb-3'>
              {this.props.showAvatar &&
                <Row>
                  <Col xs={12}>
                    <Image src={item.avatarSmall} className='cursor' height={50} roundedCircle onClick={() => { this.props.onClick(item) }} />
                  </Col>
                </Row>
              }
              <span className='cursor' onClick={() => { this.props.onClick(item) }}> {item.displayName} </span>
            </Col>
          )
        })
      )
    }

  }

  handleFormulaChange = (e) => {
    console.log('handleFormulaChange', e.target.value)
    this.setState({ formula: e.target.value }, () => { this.fetchSearch() })

    //evt.target.checked
  }
  idInputOnFocus = () => {
    this.setState({ disableFormulas: true })
  }
  idInputOnBlur = () => {
    this.setState({ disableFormulas: false })
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />
    }

    return (
      <Row noGutters>
        <Col xs={12} className='p-2'>
          <Row noGutters>
            <Col xs={12} className='border p-2'>
              <Row noGutters>
                <Col xs={12} className='border py-3 text-center'>
                  <Row noGutters>
                    <Col xs={12}>
                      <h3>SZUKAJ</h3>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs={12} className='pb-3'>
                      <Form.Check
                        inline
                        label="Starts with"
                        name="formulaCheck"
                        value={'startswith'}
                        type={'radio'}
                        onChange={this.handleFormulaChange.bind(this)}
                        id={'formuaCheck1'}
                        checked={this.state.formula === 'startswith' ? true : false}
                        disabled={this.state.disableFormulas}
                      />
                      <Form.Check
                        inline
                        label="Contains"
                        name="formulaCheck"
                        value={'contains'}
                        type={'radio'}
                        id={'formuaCheck2'}
                        onChange={this.handleFormulaChange.bind(this)}
                        checked={this.state.formula === 'contains' ? true : false}
                        disabled={this.state.disableFormulas}
                      />
                      <Form.Check
                        inline
                        label="Equal"
                        name="formulaCheck"
                        value={'equal'}
                        type={'radio'}
                        id={'formuaCheck3'}
                        onChange={this.handleFormulaChange.bind(this)}
                        checked={this.state.formula === 'equal' ? true : false}
                        disabled={this.state.disableFormulas}
                      />
                    </Col>
                  </Row>
                  {!this.props.isVerified &&
                    <Row noGutters>
                      <Col xs={12} className='pb-3'>
                        <Form.Check
                          inline
                          label="Wszyscy"
                          name="isVerifiedCheck"
                          value={'all'}
                          type={'radio'}
                          onChange={() => this.setState({ isVerified: 'all' })}
                          id={'Zweryfikowani1'}
                          checked={this.state.isVerified === 'all' ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                        <Form.Check
                          inline
                          label="Twórcy"
                          name="isVerifiedCheck"
                          value={2}
                          type={'radio'}
                          onChange={() => this.setState({ isVerified: 2 })}
                          id={'Zweryfikowani2'}
                          checked={this.state.isVerified === 2 ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                        <Form.Check
                          inline
                          label="Fani"
                          name="isVerifiedCheck"
                          value={0}
                          type={'radio'}
                          onChange={() => this.setState({ isVerified: 0 })}
                          id={'Zweryfikowani3'}
                          checked={this.state.isVerified === 0 ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                        <Form.Check
                          inline
                          label="Oczekujący"
                          name="isVerifiedCheck"
                          value={1}
                          type={'radio'}
                          onChange={() => this.setState({ isVerified: 1 })}
                          id={'Zweryfikowani4'}
                          checked={this.state.isVerified === 1 ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                      </Col>
                    </Row>
                  }
                  {!this.props.gender &&
                    <Row noGutters>
                      <Col xs={12} className='pb-3'>
                        <Form.Check
                          inline
                          label="Wszyscy"
                          name="genderCheck"
                          value={'all'}
                          type={'radio'}
                          onChange={() => this.setState({ gender: 'all' })}
                          id={'gender1'}
                          checked={this.state.gender === 'all' ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                        <Form.Check
                          inline
                          label="Kobieta"
                          name="genderCheck"
                          value={1}
                          type={'radio'}
                          onChange={() => this.setState({ gender: 1 })}
                          id={'gender2'}
                          checked={this.state.gender === 1 ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                        <Form.Check
                          inline
                          label="Mężczyzna"
                          name="genderCheck"
                          value={2}
                          type={'radio'}
                          onChange={() => this.setState({ gender: 2 })}
                          id={'gender3'}
                          checked={this.state.gender === 2 ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                         <Form.Check
                          inline
                          label="Para"
                          name="genderCheck"
                          value={4}
                          type={'radio'}
                          onChange={() => this.setState({ gender: 4 })}
                          id={'gender4'}
                          checked={this.state.gender === 4 ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                        <Form.Check
                          inline
                          label="Shemale"
                          name="genderCheck"
                          value={5}
                          type={'radio'}
                          onChange={() => this.setState({ gender: 5 })}
                          id={'gender5'}
                          checked={this.state.gender === 5 ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                        <Form.Check
                          inline
                          label="Niebinarna"
                          name="genderCheck"
                          value={6}
                          type={'radio'}
                          onChange={() => this.setState({ gender: 6 })}
                          id={'gender6'}
                          checked={this.state.gender ===6 ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                        <Form.Check
                          inline
                          label="Nie określona"
                          name="genderCheck"
                          value={3}
                          type={'radio'}
                          onChange={() => this.setState({ gender: 3 })}
                          id={'gender7'}
                          checked={this.state.gender ===3 ? true : false}
                        // disabled={this.state.disableFormulas}
                        />
                      </Col>
                    </Row>

                  }

                  <Row noGutters>
                    <Col xs={12} className='pb-2'>
                      <Row noGutters>
                        <Col xs={10} md={3} className='mx-auto'>
                          <Form.Control
                            size="sm"
                            type="email"
                            placeholder="Email"
                            onChange={(e) => this.handleEmailInputOnChange(e)}
                            onPaste={(e) => this.handleEmailInputOnChange(e)}
                            value={this.state.emailInput}
                            disabled={this.state.searchFetching}
                          />
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col xs={10} md={3} className='mx-auto text-center pt-2 pb-3'>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => { this.fetchSearch() }}
                            disabled={this.state.searchFetching}
                          >
                            ZNAJDŹ EMAIL
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs={10} md={3} className='mx-auto pb-2'>
                      <Form.Control size="sm" type="text" placeholder="Pseudonim"
                        onChange={(e) => this.handleDisplayNameInputOnChange(e)}
                        value={this.state.displayNameInput}
                        onPaste={(e) => this.handleDisplayNameInputOnChange(e)}
                      />
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col xs={10} md={3} className='mx-auto pb-2'>
                      <Form.Control size="sm" type="number" placeholder="Id"
                        onChange={(e) => this.handleIdInputOnChange(e)}
                        onPaste={(e) => this.handleDisplayNameInputOnChange(e)}
                        value={this.state.idInput}
                        onFocus={(e) => this.idInputOnFocus(e)}
                        onBlur={(e) => this.idInputOnBlur(e)}
                      />
                    </Col>
                  </Row>
                  
                  <Row noGutters>
                        <Col xs={10} md={3} className='mx-auto text-center pt-2 pb-3'>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => { this.fetchSearch() }}
                            disabled={this.state.searchFetching}
                          >
                            ZNAJDŹ WSZYSTKO
                          </Button>
                        </Col>
                      </Row>
                  <Row noGutters>
                    {this.state.searchFetching &&
                      <Col xs={'auto'} className='text-center mx-auto'>
                        <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={50} width={50} />
                      </Col>
                    }

                    {!this.state.searchFetching && this.state.data && this.state.data.length === 0 &&
                      <Col xs={12} className='text-center'>
                        Brak wyników
                      </Col>
                    }
                    {!this.state.searchFetching && this.state.data && this.state.data.length > 0 &&
                      this.renderResults()
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

}



function mapStateToProps(state) {
  return {

    authToken: state.auth.token,
    loggedIn: state.auth.loggedIn,
    dashboardData: state.dashboard.dashboardData,

  };
}



function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        userSearch,

      }, dispatch)
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSearchBox);

