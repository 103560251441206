import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getMailTemplates,
    sendMail,
    getAdminMailsList,
} from "../../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import toast, { Toaster } from 'react-hot-toast';
import DialogToast from "../dialogToast";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class MailingBox extends Component {
    constructor(props) {
        super(props);
        this.selectRef = React.createRef();
        this.adminMailsSelectRef = React.createRef();
    }
    componentDidMount() {
        this.setState({ fetching: true })
        this.props.getMailTemplates(this.formFetched)
        this.props.getAdminMailsList(this.adminMailsFetched)
        
    }
    state = {
        saveInOutbox: false,
        adminMails:[],
    }

    formFetched = (data) => {
        console.log('formFetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
            this.setState({ data: data })
        }
    }
    adminMailsFetched=(data)=>{
        if (!data.error) {
            this.setState({ adminMails: data })
        }

    }
    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }
    renderSelect = () => {
        return (
            <Form.Control as="select"
                ref={this.selectRef}
                onChange={(e) => this.categoryOnChange(e)}
                disabled={this.state.fetching}
            >
                <option value="none">Wybierz kategorie...</option>
                {this.state.data.map((item, index) => {
                    return (
                        <option key={'mailcategory' + index} value={item.id}>{item.category}</option>
                    )

                })
                }
                <option value="new">Nowa wiadomość</option>
            </Form.Control>
        )

    }
    adminMailsOnChange=(e)=>{
        this.setState({mailConnection:e.target.value})
    }

    renderAdminMailsSelect = () => {
        return (
            <Form.Control as="select"
                ref={this.adminMailsSelectRef}
                onChange={(e) => this.adminMailsOnChange(e)}
                disabled={this.state.fetching}
                value={this.state.mailConnection}
            >
                {this.state.adminMails.list.map((item, index) => {
                    return (
                        <option key={'mailAdmins' + index} value={item}>
                            {item}@watchmemore.com
                        </option>
                    )
                })
                }
                {this.state.adminMails.personal&&
                <option value={this.state.adminMails.personal}>
                    {this.state.adminMails.personal}@watchmemore.com
                    </option>
                }
            </Form.Control>
        )

    }

    resetForm = (full = false) => {
        this.setState({
            radios: undefined,
            contentState: undefined,
            editorState: undefined,
            mailBody: undefined,
            subject: undefined,
            headerText:undefined,
            asMail: undefined,
            asAlert: undefined,
        })
        if (full) {
            this.setState({
                saveInOutbox: false,
                mailConnection:undefined,
            })
        }
    }
    
    categoryOnChange = (e) => {
        let val = e.target.value
        if (val === 'none') {
            this.resetForm(true)
            return
        }
        else if (val === 'new') {
            this.resetForm()
            this.setState({
                saveInOutbox: true,
            })
            if(this.state.adminMails.personal){
                this.setState({mailConnection:this.state.adminMails.personal})
            }
            else{
                this.setState({mailConnection:this.state.adminMails.default})
            }
            let item = {
                title: '',
                content: ''
            }
            this.handleRadio(item)
            return
        }
        else {
            this.resetForm(true)
            console.log('this.state.adminMails.default', this.state.adminMails.default)
            this.setState({mailConnection:this.state.adminMails.default})
            
        }

        // console.log('val', val)
        let obj = this.state.data.find(element => element.id == val);
        if (obj) {
            console.log('obj.adminMailContent', obj.adminMailContent)
            this.setState({ radios: obj.adminMailContent })
        }

    }

    renderMailBodiesRadios = () => {
        return (
            this.state.radios.map((item, index) => {
                return (
                    <Form.Check
                        className="pb-2"
                        key={'mailradio' + index}
                        label={item.shortDesc}
                        name={'mailBodyRadio'}
                        value={item}
                        type={'radio'}
                        onChange={(e) => this.handleRadio(item)}
                        id={'mailradio' + index}
                        checked={this.state.mailBody === item ? true : false}
                        disabled={this.state.fetching}
                    />
                )
            })
        )
    }
    handleRadio = (item) => {
        const contentBlock = htmlToDraft(item.content);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({ contentState: contentState, editorState: editorState, mailBody: item, subject: item.title })
        }
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    
    send = (confirmed = false) => {
        toast.dismiss()
        let mailContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        console.log('mailContent', mailContent, mailContent.length)

        if (this.state.subject.length === 0) {
            toast.error((t) => { return this.renderToastContent(t, 'Wpisz tytuł wiadomości') })
            return
        }
        if (mailContent.length < 9) {
            toast.error((t) => { return this.renderToastContent(t, 'Wiadomość nie może być pusta') })
            return
        }
        if (mailContent.length < 30) {
            toast.error((t) => { return this.renderToastContent(t, 'Czy wiadomość nie jest za krótka? Minimum 30 znaków!') })
            return
        }
        if (!this.state.asMail && !this.state.asAlert) {
            toast.error((t) => { return this.renderToastContent(t, 'Musisz wybrać Mail lub Alert') })
            return
        }
        if (!confirmed) {
            let adminMailInfo=''
            let saveOutboxInfo=''
            if(this.state.asMail){
                adminMailInfo=' jako '+this.state.mailConnection+'@watchmemore.com'
            }
            if(this.state.saveInOutbox){
                saveOutboxInfo=' i zapisać w folderze wysłane'
            }
            DialogToast('Czy napewno chcesz wysłać tą wiadomość'+adminMailInfo+saveOutboxInfo+'?', () => this.send(true))
            return
        }
        const fd = new FormData()
        if (this.state.asMail) {
            fd.append('asMail', true)
        }
        if (this.state.asAlert) {
            fd.append('asAlert', true)
        }

        fd.append('subject', this.state.subject)
        fd.append('mailBody', mailContent)
        if(this.state.headerText){
            fd.append('headerText', this.state.headerText)
        }
        fd.append('mailConnection', this.state.mailConnection)
        
        let adminLog = ''
        adminLog = 'Wysłany '
        if (this.state.asMail) {
            adminLog += 'mail '
        }
        if (this.state.asAlert) {
            adminLog += 'alert '
        }
        adminLog += '- '
        if (this.state.mailBody.adminLog) {
            adminLog += this.state.mailBody.adminLog
        }
        else {
            //customowa tresc maila
            adminLog += this.state.subject + '<p>' + mailContent + '</p>'
           // fd.append('saveInOutbox', true)

        }
        if (this.state.saveInOutbox) {
            fd.append('saveInOutbox', true)
        }
        fd.append('adminLogs', adminLog)
        this.setState({ fetching: true })
        this.props.sendMail(this.props.user.id, fd, this.mailSent)
    }
    mailSent = (data) => {
        this.setState({ fetching: false })
        toast.dismiss()
        if (!data.error) {
            this.selectRef.current.value = 'none'
            this.resetForm(true)
            toast.success((t) => { return this.renderToastContent(t, 'Wiadomość została wysłana.') })

        }
        else {
            console.error('error', data)
            if (data.message) {
                toast.error((t) => { return this.renderToastContent(t, data.message) })
            }
            else {
                toast.error((t) => { return this.renderToastContent(t, data) })
            }
        }

    }
    render() {
        return (
            <Row noGutters>
                <Col xs={12} className='border p-3'>
                    <Toaster />
                    <Row noGutters>
                        <Col xs={12} className='text-center border p-3'>

                            <Row noGutters>
                                <Col xs={12} className='text-center pb-2'>
                                    <h5>MAILING:</h5>
                                </Col>
                            </Row>
                            {this.state.data &&
                                <Row noGutters>
                                    <Col xs={12} md={5} className='mx-auto text-center'>
                                        {this.renderSelect()}
                                    </Col>
                                </Row>
                            }
                            {this.state.radios &&
                                <Row noGutters>
                                    <Col xs={11} md={4} className='mx-auto text-left pt-3'>
                                        {this.renderMailBodiesRadios()}
                                    </Col>
                                </Row>
                            }
                            {this.state.mailBody &&
                                <Row noGutters>
                                    <Col xs={12}>
                                        <Row noGutters>
                                            <Col xs={11} md={5} className='mx-auto text-left mt-3 '>
                                                <strong>Tytuł:</strong>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Tytuł wiadomości"
                                                    value={this.state.subject}
                                                    onChange={(e) => { this.setState({ subject: e.target.value }) }}
                                                    disabled={this.state.fetching}
                                                />
                                            </Col>
                                        </Row>
                                        <Row noGutters>
                                            <Col xs={11} md={5} className='mx-auto text-left mt-3 '>
                                                <strong>Nagłówek: (tylko mail, wyswietla sie w tresci maila)</strong>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Tytuł wiadomości"
                                                    value={this.state.headerText}
                                                    onChange={(e) => { this.setState({ headerText: e.target.value }) }}
                                                    disabled={this.state.fetching}
                                                />
                                            </Col>
                                        </Row>
                                        <Row noGutters>
                                            <Col xs={11} md={5} className='mx-auto text-left mt-3 '>
                                                <strong>Treść:</strong>
                                                <Editor
                                                    editorState={this.state.editorState}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="px-2 border"
                                                    onEditorStateChange={this.onEditorStateChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <Row noGutters>
                                            <Col xs={11} md={4} className='mx-auto mt-2 text-left '>
                                                <Form.Check
                                                    label={'Jako Alert'}
                                                    name={'sendVerificationAlert'}
                                                    value={this.state.asAlert}
                                                    type={'checkbox'}
                                                    onChange={(e) => this.setState({ asAlert: e.target.checked })}
                                                    id={'alertCheck'}
                                                    checked={this.state.asAlert}
                                                    disabled={this.state.fetching}
                                                />
                                            </Col>
                                        </Row>
                                        <Row noGutters>
                                            <Col xs={11} md={4} className='mx-auto mt-3 text-left '>
                                                <Form.Check
                                                    label={'Jako Mail'}
                                                    name={'sendVerificationMail'}
                                                    value={this.state.asMail}
                                                    type={'checkbox'}
                                                    onChange={(e) => this.setState({ asMail: e.target.checked })}
                                                    id={'mailCheck'}
                                                    checked={this.state.asMail}
                                                    disabled={this.state.fetching}
                                                />
                                            </Col>
                                        </Row>

                                        {this.state.asMail &&
                                            <Row noGutters>
                                                <Col xs={12}>
                                                    <Row noGutters>
                                                        <Col xs={11} md={4} className='mx-auto mt-2 text-left '>
                                                            Wyslij z konta:
                                                        </Col>
                                                    </Row>
                                                    <Row noGutters>
                                                        <Col xs={11} md={4} className='mx-auto mt-2 text-left '>
                                                            {this.renderAdminMailsSelect()}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        }
                                        {this.state.asMail &&
                                            <Row noGutters>
                                                <Col xs={11} md={4} className='mx-auto mt-2 text-left '>
                                                    <Form.Check
                                                        label={'Zapisz w wysłanych wiadomościach na serwerze'}
                                                        name={'saveInOutboxCheck'}
                                                        value={this.state.asAlert}
                                                        type={'checkbox'}
                                                        onChange={(e) => this.setState({ saveInOutbox: e.target.checked })}
                                                        id={'saveInOutboxCheck'}
                                                        checked={this.state.saveInOutbox}
                                                        disabled={this.state.fetching}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        <Row noGutters>
                                            <Col xs={11} md={6} className='text-center mx-auto mt-3 pt-2 pb-3 px-2 '>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => { this.send() }}
                                                    disabled={this.state.fetching}
                                                >
                                                    WYŚLIJ
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}
function mapStateToProps(state) {
    return {
        // adminLogs: state.user.adminLogs,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getMailTemplates,
                sendMail,
                getAdminMailsList,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MailingBox);