import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getRefundRequests,
} from "../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import Table from 'react-bootstrap/Table';

class Refundacje extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
        this.setState({ fetching: true })
        this.props.getRefundRequests(this.dataFetched)
    }

    state = {
        fetching: false,

    }
    dataFetched = (data) => {
        console.log('Refundacje dataFetched', data)
        this.setState({ fetching: false })
        this.setState({ data: data })

    }
    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }
    showDetails = (item) => {
        console.log('showDetails', item)
        this.setState({ redirect: '/zarobki/details/' + item.id + '/' })
        ///zarobki/details/:id/
    }
    renderList = () => {
        return (
            this.state.data.map((item, index) => {
                //1- abonament, 2- tip, 3-referral program, 4 - ppv, 5 - snapy
                let rodzaj = ''
                if (item.zarobek.rodzaj === 1) {
                    rodzaj = 'Sub'
                }
                else if (item.zarobek.rodzaj === 2) {
                    rodzaj = 'Tip'
                }
                else if (item.zarobek.rodzaj === 3) {
                    rodzaj = 'Pp'
                }
                else if (item.zarobek.rodzaj === 4) {
                    rodzaj = 'PPV'
                }
                else if (item.zarobek.rodzaj === 5) {
                    rodzaj = 'Sn'
                }
                else {
                    rodzaj = 'Inny'
                }

                return (
                    <tr key={'kupionetem' + index} className='cursor'>
                        <td className="cursor" onClick={() => this.showDetails(item)}>
                            {item.time}
                        </td>
                        <th scope="row" className="capitalize">
                            <strong>
                                <a href={"/user/" + item.user.id + "/"} target="_blank">
                                    {item.user.displayName}
                                </a>
                            </strong>
                        </th>
                        <th scope="row" className="capitalize">
                            <strong>
                                <a href={"/user/" + item.blogger.id + "/"} target="_blank">
                                    {item.blogger.displayName}
                                </a>
                            </strong>
                        </th>
                        <td className="">
                            {rodzaj}
                        </td>
                        <td className=" text-danger" >
                            {item.zarobek.kwota}{item.zarobek.walutaTransakcji}
                        </td>

                        <td className="text-center, text-danger, cursor"

                        >
                            <a href={"/zarobki/details/" + item.zarobek.id + "/"} target="_blank">
                                Zarządzaj
                            </a>
                        </td>
                    </tr>
                )
            })
        )
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        return (
            <Col xs={12} className='border p-3 text-center'>
                <Toaster />
                <h5>PROŚBY O REFUNDACJE:</h5>
                <Row noGutters>
                    <Col xs={12} className='text-center border p-3'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th scope="col" >Data</th>
                                    <th scope="col" >Klient</th>
                                    <th scope="col" >Twórca</th>
                                    <th scope="col">Typ</th>
                                    <th scope="col">Kwota</th>
                                    <th scope="col">-</th>
                                </tr>
                            </thead>
                            <tbody >
                                {this.state.data &&
                                    this.renderList()
                                }
                            </tbody>
                        </Table>

                    </Col>
                </Row>
            </Col>
        )
    }

}
function mapStateToProps(state) {
    return {
        // inWatched:state.user.inWatched,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getRefundRequests,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Refundacje);