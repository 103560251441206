import { createReducer } from '@reduxjs/toolkit'
const initialState = {
    user: undefined,
    profile: undefined,
    inWatched:undefined,
    declineReasons:undefined,
    nextActions:undefined,
    inSuspected:undefined,
    adminNotes:[],
    adminLogs: [],
}
const user = createReducer(initialState, (builder) => {
    builder
        .addCase('SET_USER_DATA', (state, action) => {
             console.log('reducer SET_USER_DATA', action.data)
            state.user = action.data
        })
        .addCase('SET_PROFILE_DATA', (state, action) => {
            state.profile = action.data
        })
        .addCase('SET_ADMIN_LOGS_DATA', (state, action) => {
            state.adminLogs = action.data
        })
        .addCase('SET_IN_WATCHED', (state, action) => {
            state.inWatched = action.data
        })
        .addCase('SET_IN_SUSPECTED', (state, action) => {
            state.inSuspected = action.data
        })
        .addCase('SET_DECLINE_REASONS', (state, action) => {
            state.declineReasons = action.data
        })
        .addCase('SET_NEXT_ACTIONS', (state, action) => {
            state.nextActions = action.data
        })
        .addCase('SET_ADMIN_NOTES', (state, action) => {
            state.adminNotes = action.data
        })

        
})

export default user