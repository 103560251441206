
import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import toast, { Toaster } from 'react-hot-toast';

function DialogToast(msg, yes) {
    return (
        toast((t) => {
            return (
                <Row noGutters>
                    <Col>
                        <Row noGutters>
                            <Col xs={12} className='text-center pt-2 pb-4'>
                                {msg}
                            </Col>
                            <Col xs={6} className='text-center pb-2' >
                                <Button size={'sm'} variant='danger' onClick={() => { yes() }}>
                                    TAK
                                </Button>
                            </Col>
                            <Col xs={6} className='text-center pb-2' >
                                <Button size={'sm'} variant='danger' onClick={() => { toast.dismiss(t.id) }}>
                                    NIE
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                    <div onClick={() => {  toast.dismiss(t.id)}}
                        style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                    >
                        X
                    </div>
                </Row>

            )},{
                duration:Infinity
            })
        )
  }
export default DialogToast