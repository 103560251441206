
import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getUser,
    getVerificationPictures,
    sendVerification,
    addToWatched,
    removeFromWatched,
} from "../actions/index";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Oval } from 'react-loader-spinner'
import { Redirect } from 'react-router'
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { AiOutlineDown } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";

import DashboardBox from "../components/dashboardBox";
import UserSearchBox from "../components/userSearchBox";
import VerificationPicture from "../components/verificationPicture";
import ImageAnalizeModal from "../components/imageAnalizeModal";
import Header from "../components/userView/header";
import DjangoAdmin from "../components/userView/djangoAdmin";
import { BsClock } from 'react-icons/bs';
import { genders, profileStatus } from '../constants/common'
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";


import toast, { Toaster } from 'react-hot-toast';
import DialogToast from "../components/dialogToast";
import VerificationBox from '../components/userView/verificationBox'
import InWatchedBox from '../components/userView/inWatchedBox'
import InSuspectedBox from '../components/userView/inSuspectedBox'
import NotatkiBox from '../components/userView/notatkiBox'
import AdminLogsBox from '../components/userView/adminLogsBox'
import MailingBox from '../components/userView/mailingBox'
import PayoutDataBox from '../components/userView/payoutDataBox'
import OgraniczeniaBox from '../components/userView/ograniczeniaBox'
import KupioneBox from '../components/userView/kupioneBox'
import SprzedaneBox from '../components/userView/sprzedaneBox'
import SaleSummaryBox from '../components/userView/saleSummaryBox'
import FansBox from '../components/userView/fansBox'
import PayoutsBox from '../components/userView/payoutsBox'
class UserView extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
        let id = this.props.match.params.id
        let token = localStorage.getItem("token")
        this.setState({ id: id, authToken: token },
            () => {
                this.fetchData()
            })
    }

    componentWillUnmount() {
    }

    state = {

    }
    fetchData = () => {
        this.setState({ fetching: true, })
        this.props.getUser(this.state.id, this.dataFetched)

    }
    verPicDataFetched = (data) => {
        console.log('verPicDataFetched', data)
        this.setState({ verPicturesFetching: false })
        if (!data.error) {
            this.setState({ verPicsData: data })
        }
    }
    dataFetched = (data) => {
        console.log('dataFetched', data)
        this.setState({ fetching: false })
        if (!data.error) {
        }
    }
    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }



    handleInWatched = (inWatched) => {
        this.setState({ inWatched: inWatched, inWatchedFetching: true })
        if (inWatched) {
            this.props.addToWatched(this.state.id, this.watchedFetched)
        }
        else {
            this.props.removeFromWatched(this.state.id, this.watchedFetched)
        }
    }

    watchedFetched = (data) => {
        this.setState({ inWatchedFetching: false })
        if (!data.error) {
            toast.success((t) => { return this.renderToastContent(t, data.message) })
        }
        else {
            toast.error((t) => { return this.renderToastContent(t, data.data) })
        }

    }

    render() {
        if (!this.props.loggedIn) {
            return <Redirect to={'/login/'} />
        }
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        if (this.state.fetching) {
            return (
                <div style={{ zIndex: '100', backgroundColor: 'rgba(255,255,255,0.4)', position: 'absolute', left: '0px', right: '0px', top: '0px', bottom: '0px' }}>
                    <Container fluid className='d-flex flex-column h-100 justify-content-center align-items-center' style={{}}>
                        <Oval color="rgba(244, 67, 54, 0.5)" secondaryColor="rgba(244, 67, 54, 0.8)" height={125} width={125} />
                    </Container>
                </div>
            )
        }

        if (this.props.profile && this.props.user) {
            return (
                <Col xs={12} className='p-0'>
                    <Toaster />
                    <Row noGutters>
                        <Col xs={12} className='p-0'>
                            <Row noGutters>
                                <Col className='text-center'><h3>DANE PROFILU</h3></Col>
                            </Row>
                            <Row noGutters>
                                <Col xs={12} className='border p-2'>
                                    <Header {...this.props} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} className='p-0'>
                            <DjangoAdmin data={this.props} />
                            <Row noGutters>
                                <Col xs={12} className='py-3 text-center border-top border-bottom '>
                                    <Link to={'/chaty/'+this.props.user.id+'/'} target={'_blank'}>CHATY</Link><br></br>
                                    profil/media/zakupy/sprzedaz/... navbar
                                </Col>
                            </Row>
                            <VerificationBox {...this.props} />
                            <InWatchedBox {...this.props} />
                            <InSuspectedBox {...this.props} />
                            <NotatkiBox {...this.props} />
                            <AdminLogsBox {...this.props} />
                            <MailingBox {...this.props} />
                            <PayoutDataBox {...this.props} />
                            <OgraniczeniaBox {...this.props} />
                            <KupioneBox {...this.props} />
                            <SaleSummaryBox {...this.props} />
                            <SprzedaneBox {...this.props} />
                            <PayoutsBox {...this.props} />
                            <FansBox {...this.props} />
                          

                        </Col>
                    </Row >
                </Col>
            )
        }
    }

}



function mapStateToProps(state) {
    return {

        authToken: state.auth.token,
        loggedIn: state.auth.loggedIn,
        dashboardData: state.dashboard.dashboardData,
        user: state.user.user,
        profile: state.user.profile,
        adminLogs: state.user.adminLogs,
        inWatched: state.user.inWatched,
        declineReasons: state.user.declineReasons,
        nextActions: state.user.nextActions,
        inSuspected: state.user.inSuspected,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getUser,
                getVerificationPictures,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserView);

