import { createReducer } from '@reduxjs/toolkit'
const initialState={
    token:null,
    loggedIn:false,
    loginCompleted:false,
    config:null,
    userBanned:false

}
const auth = createReducer(initialState, (builder) => {
    builder
      .addCase('SET_AUTH_TOKEN', (state, action) => {
      //  console.log('reducer SET_AUTH_TOKEN', action.data)
        state.token=action.data
      })
      .addCase('SET_LOGGED_IN', (state, action) => {
        state.loggedIn= action.data
      })
      .addCase('SET_LOGIN_COMPLETED', (state, action) => {
        state.loginCompleted= action.data
      })
      .addCase('SET_CONFIG', (state, action) => {
        state.config= action.data
      })
      .addCase('SET_USER_BANNED', (state, action) => {
        state.userBanned= action.data
      })
      .addCase('RESET_ALL', (state, action) => {  
      //  console.log('RESET_ALL auth')
        return initialState
      })
  })



export default auth