import React, { Component } from "react";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import {
    getChatBlogers,
    getUserChatList,
    getChatDetails,
    banChatMessage,
} from "../actions/index";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import toast, { Toaster } from 'react-hot-toast';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router'
import Table from 'react-bootstrap/Table';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component'
import { AiOutlineLeft, AiOutlineClose } from 'react-icons/ai';
import { BiSearch, BiDotsHorizontalRounded } from 'react-icons/bi'
import VideoPlayer from "../components/videoPlayer";
//inport Spinner
import Spinner from 'react-bootstrap/Spinner'
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import ChatMessageItem from '../components/chat/chatMessageItem'
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
const licenseKey = 'EBAD8C94-B5724B8E-8EB24C0A-8A800C2E'

class Chats extends Component {
    constructor(props) {
        super(props);
        this.chatItemsWrapperRef = React.createRef();

    }
    componentDidMount() {
        this.naviStateChanged()

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match !== this.props.match) {
            //Perform some operation here
            this.setState({ redirectNoPush: false, redirect: false })
            this.naviStateChanged()
        }
    }

    state = {
        bloggersListdata: [],
        userChatList: [],
        chatDetailsData: undefined,
        fetching: false,
        show: 'bloggersList',
        blogersListHasMorePages: true,
        bloggersListfetching: false,
        blogersListPage: 1,
        userListHasMorePages: true,
        userListfetching: false,
        userListPage: 1,
        chatDetailsPage: 1,
        chatDetailsHasMorePages: true,
        chatDetailsfetching: false,
        itemsWrapperLastHeight: 0,
        bloggerSearch: '',
    }


    naviStateChanged = () => {
        let userId = this.props.match.params.userId
        let chatId = this.props.match.params.chatId
        console.log('naviStateChanged', userId, chatId)
        if (userId && chatId) {
            this.setState({
                show: 'chatDetails',
                chatDetailsData: undefined,
                chatDetailsPage: 1,
                chatDetailsfetching: false,
                userId: userId,
                chatId: chatId,
                chatDetailsHasMorePages: true,
                selectedChat: parseInt(chatId),
            }, () => {

                this.nextChatDetailsPage()
                if (this.state.userChatList.length === 0) {
                    this.nextUserChatListPage(userId)
                }
            })

        }
        else if (userId && !chatId) {
            this.setState({ userChatList: [], userId: userId, show: 'userChatList', userListPage: 1, userListHasMorePages: true, chatDetailsfetching: false }, () => {
                this.nextUserChatListPage(userId)
            })

        }
        else {
            this.setState({ userChatList: [], userListHasMorePages: true, userListfetching: false, selectedBlogger: undefined, show: 'bloggersList' })
            //this.setState({ fetching: true, blogersListPage: 1, bloggersListdata: [], blogersListHasMorePages: true })
            if (this.state.bloggersListdata.length === 0) {
                this.nextBloggersListPage()
            }

            // this.props.getChatBlogers(this.bloggersListFetched)
        }
    }
    clearBloggerSearch = () => {
        clearTimeout(this.state.bloggerSearchChangeTimeoutId)
        this.setState({ blogersListPage: 1, bloggersListdata: [], bloggerSearch: '', blogersListHasMorePages: true }, () => {
            this.nextBloggersListPage()
        })
    }

    onBloggerSerachChange = (e) => {
        clearTimeout(this.state.bloggerSearchChangeTimeoutId)
        let bloggerSearchChangeTimeoutId = setTimeout(() => {
            this.setState({ blogersListPage: 1, bloggersListdata: [], blogersListHasMorePages: true }, () => {
                this.nextBloggersListPage()
            })
        }, 800)
        this.setState({ bloggerSearch: e.target.value, bloggerSearchChangeTimeoutId: bloggerSearchChangeTimeoutId })
    }

    nextBloggersListPage = () => {
        //   console.log('BloggersListPage nextBloggersListPage')
        if (this.state.blogersListHasMorePages && this.state.bloggersListfetching === false) {
            this.setState({ bloggersListfetching: true }, () => {
                this.props.getChatBlogers(this.bloggersListFetched, this.state.blogersListPage, this.state.bloggerSearch)

            })
        }
    }

    bloggersListFetched = (data) => {
        // console.log('BloggersListPage bloggersListFetched', data)

        if (!data.error) {
            //create deep copy of this.state.bloggersListdata
            let bloggersListdata = JSON.parse(JSON.stringify(this.state.bloggersListdata))
            //append data to deep copy
            data = [...bloggersListdata, ...data]
            this.setState({ bloggersListdata: data, blogersListPage: this.state.blogersListPage + 1, bloggersListfetching: false })

            this.setState({ blogersListHasMorePages: true })
        }
        else {
            this.setState({ bloggersListfetching: false })
            this.setState({ blogersListHasMorePages: false })
        }

    }


    nextUserChatListPage = () => {
        console.log('nextUserChatListPage')
        //  console.log('this.state.userListPage', this.state.userListPage)
        if (this.state.userListHasMorePages && this.state.userListfetching === false) {
            this.setState({ userListfetching: true }, () => {
                this.props.getUserChatList(this.state.userId, this.userChatListFetched, this.state.userListPage)
            })
        }


    }

    userChatListFetched = (data) => {
        console.log('userChatListFetched')
        //    console.log('CHATY userChatListFetched', data)
        if (!data.error) {
            //create deep copy of this.state.bloggersListdata
            let userChatList = JSON.parse(JSON.stringify(this.state.userChatList))
            data = [...userChatList, ...data]
            //      console.log('new userChatist data', data)
            this.setState({ userChatList: data, userListPage: this.state.userListPage + 1, userListfetching: false })
            this.setState({ userListHasMorePages: true })
        }
        else {
            this.setState({ userListfetching: false })
            this.setState({ userListHasMorePages: false })
        }
    }

    nextChatDetailsPage = () => {
        console.log('CHATY nextChatDetailsPage')
        if (this.state.chatDetailsHasMorePages && this.state.chatDetailsfetching === false) {
            this.setState({ chatDetailsfetching: true }, () => {
                //calculate scroll distance from bottom
                try {
                    let scrollDistanceFromBottom = this.chatItemsWrapperRef.current.scrollHeight - this.chatItemsWrapperRef.current.scrollTop - this.chatItemsWrapperRef.current.clientHeight
                    this.setState({ itemsWrapperLastHeight: scrollDistanceFromBottom })
                }
                catch (e) {
                    console.log('error calculating scroll distance from bottom', e)
                }
                this.props.getChatDetails(this.state.chatId, this.state.chatDetailsPage, this.chatDetailsFetched)
                //this.props.getChatDetails( this.chatDetailsFetched)
            })
        }
    }
    chatDetailsFetched = (data) => {
        console.log('CHATY chatDetailsFetched', this.state.chatDetailsPage)
        if (!data.error) {
            //create deep copy of this.state.bloggersListdata
            if (this.state.chatDetailsData) {
                let chatDetailsData = JSON.parse(JSON.stringify(this.state.chatDetailsData))
                chatDetailsData.replys = [...data.replys, ...chatDetailsData.replys]
                data = chatDetailsData
                console.log('new chatDetailsData data', data)
            }
            else {
                data = data
            }

            this.setState({ chatDetailsData: data, chatDetailsPage: this.state.chatDetailsPage + 1, chatDetailsfetching: false }, () => {
                if (this.state.chatDetailsPage > 1) {
                    setTimeout(() => {
                        //scroll this.chatItemsWrapperRef.current from bottom to this.state.itemsWrapperLastHeight
                        try {
                            this.chatItemsWrapperRef.current.scrollTop = this.chatItemsWrapperRef.current.scrollHeight - this.state.itemsWrapperLastHeight
                        }
                        catch (e) {
                        }
                        //this.chatItemsWrapperRef.current.scrollTop = this.state.itemsWrapperLastHeight
                    }, 100);
                }
            })

            if (data.replys.length === 0) {
                this.setState({ chatDetailsHasMorePages: false })
            }
            else {
                this.setState({ chatDetailsHasMorePages: true })
            }
        }
        else {
            this.setState({ chatDetailsfetching: false })
            this.setState({ chatDetailsHasMorePages: false })
        }
    }


    renderToastContent = (t, msg) => {
        return (
            <div style={{ paddingRight: '25px' }}>
                {msg}
                <div onClick={() => { toast.dismiss(t.id) }}
                    style={{ position: 'absolute', right: '10px', top: '10px', bottom: '0px', cursor: 'pointer' }}
                >
                    X
                </div>
            </div>
        )
    }
    renderBloggersListItems = () => {
        let items = this.state.bloggersListdata.map((item, index) => {
            //1- abonament, 2- tip, 3-referral program, 4 - ppv, 5 - snapy=
            return (
                <div key={'bloggUsList' + index}>
                    <Link to={'/chaty/' + item.blogger.id + '/'} onClick={() => { this.setState({ selectedBlogger: item }) }}>
                        <Row key={'chList' + index} noGutters >
                            <Col xs={12} className='py-2 border mb-2'>
                                <Row noGutters>
                                    <Col xs={'auto'} className='px-2'>
                                        <Image src={item.blogger.avatarSmall} height={50} roundedCircle />
                                    </Col>
                                    <Col>
                                        <Row noGutters>
                                            <Col className="upperCase text-left py-3">
                                                {item.blogger.displayName}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Link>
                </div>
            )
        })
        return items
    }

    replaceUrl = (url, push = false) => {
        //replace url without reloading page

        if (push) {
            this.props.history.push(url);
        }
        else {
            this.props.history.replace(url);
        }

    }


    renderUserChatListItems = () => {
        if (!this.state.userChatList) {
            return null
        }
        let userId = this.props.match.params.userId;

        let items = this.state.userChatList.map((item, index) => {
            //    console.log('item', item)
            
            let avatar;
            let displayName;
            if (userId == item.blogger.id) {
                avatar = item.user.avatarSmall
                displayName = item.user.displayName
            }
            else {
                avatar = item.blogger.avatarSmall
                displayName = item.blogger.displayName
            }
            let selected = ''
            if (item.id === this.state.selectedChat) {
                selected = 'selected'
            }
            return (
                <Row key={'userUsList' + index} noGutters onClick={() => {
                    console.log('item.id===this.state.selectedChat', item.id, this.state.selectedChat);
                    isMobile ? this.replaceUrl('/chaty/' + this.state.userId + '/' + item.id + '/', true) : this.replaceUrl('/chaty/' + this.state.userId + '/' + item.id + '/')
                }}>
                    <Col xs={12} className={'chatListItem py-2 border mb-2 cursor ' + selected}>
                        <Row noGutters>
                            <Col xs={'auto'} className='px-2'>
                                <Image src={avatar} height={50} roundedCircle />
                            </Col>
                            <Col>
                                <Row noGutters>
                                    <Col className="upperCase text-left py-3">
                                        {displayName}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        })
        return items
    }


    banMessage = (item, banType) => {
        const fd = new FormData();
        fd.append('action', 'ban');
        fd.append('value', banType);
        this.props.banChatMessage(item.id, fd, this.banFetched)

    }
    banFetched=(data)=>{
        this.setState({banFetching:false})
        console.log(data)
        if(!data.error){
            //find message in chatDetailsData and change banned value. mahe deep copy of chatDetailsData
            let chatDetailsData = JSON.parse(JSON.stringify(this.state.chatDetailsData))
            let index = chatDetailsData.replys.findIndex(x=>x.id===data.id)
            chatDetailsData.replys[index]= data
            this.setState({chatDetailsData:chatDetailsData})

        }
    }
    renderOverlayTrigger = (item) => {
        return (
                <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                        <Popover id="popover-basic">
                             <Popover.Title  as="h3">
                                Admin
                             </Popover.Title >
                            <Popover.Content>
                                {!item.banned&&
                                    <Button size={'sm'} variant={'outline-danger'} onClick={()=>this.banMessage(item, true)} className='w-100 mb-2'>Zbanuj</Button>
                                }
                                {item.banned&&
                                    <Button size={'sm'} variant={'outline-danger'} onClick={()=>this.banMessage(item, false)} className='w-100 mb-2'>Odbanuj</Button>
                                }
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <BiDotsHorizontalRounded style={{fontSize:'28px', cursor:'pointer'}}/>
                </OverlayTrigger>
        )
    }

    customVideoSlide = (item) => {
        return (
            <div className="col-lg-12 col-md-12 col-sm-12">
                <VideoPlayer video={item.video} autoplay={true} height={'80vh'} />
            </div>
        )
    }

    renderLightbox = () => {
        if (!this.state.lightboxData) {
            return null
        }
        let slides = []
        let initialIndex;
        let media = [...this.state.lightboxData.media]
        media = media.reverse()
        media.map((item, index) => {
            let slide = {}
            if (item.filename) {
                slide.src = item.filename
                slide.alt = ''
            }
            if (item.videoname) {
                slide.type = 'custom-video'
                slide.video = item
            }
            if (item === this.state.lightboxData.initialItem) {
                initialIndex = index
            }
            slides.push(slide)

        })

        return (
            <Lightbox
                className="lightbox"
                controller={{ closeOnBackdropClick: true }}
                open={this.state.lightboxOpen}
                close={() => this.setState({ lightboxOpen: false, lightboxData: undefined })}
                index={initialIndex}
                slides={slides}
                plugins={[Zoom, Fullscreen]}
                zoom={{
                    maxZoom: 5000
                }}
                render={{
                    slide: ({ slide }) =>
                        slide.type === "custom-video" ? (
                            this.customVideoSlide(slide)
                        ) : undefined,
                }}
            /** 
            slides={[
                {
                    src: "/image1x3840.jpg",
                    alt: "image 1",
                    width: 3840,
                    height: 2560,
                    srcSet: [
                        { src: "/image1x320.jpg", width: 320, height: 213 },
                        { src: "/image1x640.jpg", width: 640, height: 427 },
                        { src: "/image1x1200.jpg", width: 1200, height: 800 },
                        { src: "/image1x2048.jpg", width: 2048, height: 1365 },
                        { src: "/image1x3840.jpg", width: 3840, height: 2560 },
                    ],
                },
                // ...
            ]}
            */
            />
        )
    }

    lightboxOpen = (item) => {
        this.setState({ lightboxOpen: true, lightboxInitialItem: item })
    }
    setlightboxData = (data) => {
        console.log('setlightboxData', data)
        this.setState({ lightboxData: data }, () => {
            this.setState({ lightboxOpen: true })
        })
    }
    
    renderUserChatItems = () => {
        if (!this.state.chatDetailsData) {
            return null
        }
        if (!this.state.chatDetailsData.conversation) {
            return null
        }
        if (!this.state.chatDetailsData.replys) {
            return null
        }
        let bloggerId = this.state.chatDetailsData.conversation.blogger.id
        let userId = this.state.chatDetailsData.conversation.user.id
        let items = this.state.chatDetailsData.replys.map((item, index) => {
            return <ChatMessageItem data={item} 
                                    bloggerId={bloggerId} 
                                    userId={userId}
                                    renderOverlayTrigger={this.renderOverlayTrigger(item)}
                                    setlightboxData={this.setlightboxData}
                                    
                                    />
            //   console.log(item)
            let position;
            let banned=''
            if (item.sender.id === bloggerId) {
                position = 'left'
            }
            if (item.sender.id === userId) {
                position = 'right'
            }
            if(item.banned){
                banned=' banned'
            }
           

        })
        return items
    }

    xxxxrenderUserChatItems = () => {
        if (!this.state.chatDetailsData) {
            return null
        }
        let bloggerId = this.state.chatDetailsData.conversation.blogger.id
        let userId = this.state.chatDetailsData.conversation.user.id
        let items = this.state.chatDetailsData.replys.map((item, index) => {
            //   console.log(item)
            let position;
            let banned=''
            if (item.sender.id === bloggerId) {
                position = 'left'
            }
            if (item.sender.id === userId) {
                position = 'right'
            }
            if(item.banned){
                banned=' banned'
            }
           
            if (item.filename) {
                return (
                    <Row key={'chDetails' + index} noGutters >
                        <Col xs={12}>
                            <div className={"chatItemCont " + position + banned}>
                                <strong className="capitalize">{item.sender.displayName}:</strong>
                                <br></br>
                                <LightGallery
                                    closeOnTap={true}
                                    isMobile={isMobile}
                                    licenseKey={licenseKey}
                                //   onInit={onInit}
                                // speed={500}
                                //    plugins={[lgZoom]}
                                >
                                    <a href={item.filename}>
                                        <Image src={item.thumbnail} fluid />
                                    </a>
                                </LightGallery>
                                {banned&&
                                    <div className='border' style={{fontWeight:'bold'}}>
                                    ZBANOWANA
                                    </div>
                                }
                                {item.reply&&
                                    <div className="reply py-2" dangerouslySetInnerHTML={{ __html: item.reply }} />
                                }
                                <div style={{fontWeight:'bold'}}>
                                    {this.renderOverlayTrigger(item)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            }
            if (item.videoname) {
                console.log('item.videoname', item)
                return (
                    <Row key={'chDetails' + index} noGutters >
                        <Col xs={12}>
                            <div className={"chatItemCont " + position + banned}>
                                <strong className="capitalize">{item.sender.displayName}:</strong>
                                <br></br>
                                <VideoPlayer video={item} />
                                {banned&&
                                    <div className='border' style={{fontWeight:'bold'}}>
                                    ZBANOWANA
                                    </div>
                                }
                                {item.reply&&
                                    <div className="reply py-2" dangerouslySetInnerHTML={{ __html: item.reply }} />
                                }
                                <div >
                                    {this.renderOverlayTrigger(item)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            }
            //dodac video
            if (item.reply) {
                return (
                    <Row key={'chDetails' + index} noGutters >
                        <Col xs={12}>
                            <div className={"chatItemCont " + position + banned}>
                                <div className="displayName">{item.sender.displayName}:</div>
                                <div className="reply" dangerouslySetInnerHTML={{ __html: item.reply }} />
                                <Row>
                                    <Col xs={12} className='text-right date'>
                                        {item.formatedTime}
                                    </Col>
                                </Row>
                                {banned&&
                                    <div className='border' style={{fontWeight:'bold'}}>
                                    ZBANOWANA
                                    </div>
                                }
                                <div >
                                    {this.renderOverlayTrigger(item)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            }

        })
        return items
    }

    bloggersListOnScroll = (e) => {
        // console.log(e)
        //calculate scroll position
        let scrollPosition = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
        //  console.log('scrollPosition', scrollPosition)
        if (scrollPosition < 100) {
            this.nextBloggersListPage()
        }

    }
    usersListOnScroll = (e) => {
        // console.log(e)
        //calculate scroll position
        let scrollPosition = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
        //   console.log('scrollPosition', scrollPosition)
        if (scrollPosition < 100) {
            this.nextUserChatListPage()
        }

    }


    chatDetailsOnScroll = (e) => {
        // console.log(e)
        //find when scroll is on top
        let scrollPosition = e.target.scrollTop
        // console.log('scrollPosition', scrollPosition)
        if (scrollPosition < 100) {
            this.nextChatDetailsPage()
        }

    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} push />
        }
        if (this.state.redirectNoPush) {
            return <Redirect to={this.state.redirectNoPush} />
        }
        return (
            <Col xs={12} className='border p-3 text-center chatsMainCont'>
                <Toaster />
                <Row noGutters>
                    <Col className='chatMobTitle ' >
                        {/*
                        <AiOutlineLeft onClick={()=>{this.setState({redirect:'/chaty/'+this.state.userId+'/'})}}/> 
                    */}
                        <h5>CHATY {this.state.selectedBlogger && this.state.selectedBlogger.blogger.displayName}: </h5>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col xs={5} id='chatLeftMainCont' className='chatLeftMainCont border d-none d-md-block'>
                        {this.state.show === 'bloggersList' &&
                            <>
                                <Row noGutters>
                                    <Col className="py-2 px-2">
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">
                                                <BiSearch />
                                            </InputGroup.Text>
                                            <FormControl
                                                type="text"
                                                placeholder="Szukaj po nazwie"
                                                className="mr-sm-2"
                                                value={this.state.bloggerSearch}
                                                onChange={this.onBloggerSerachChange}
                                            />
                                            <InputGroup.Text id="basic-addon2" onClick={() => { this.clearBloggerSearch() }}>
                                                <AiOutlineClose />
                                            </InputGroup.Text>

                                        </InputGroup>
                                    </Col>
                                </Row>
                                <div className="chatListInnerCont" onScroll={this.bloggersListOnScroll} style={{ top: '50px' }}>
                                    {this.renderBloggersListItems()}
                                    {this.state.bloggersListfetching &&
                                        <div className="text-center">
                                            <Spinner animation="border" role="status" variant={'danger'}>
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    }
                                    {!this.state.blogersListHasMorePages &&
                                        <div className="text-center">
                                            Koniec listy
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {(this.state.show === 'userChatList' || this.state.show === 'chatDetails') &&
                            <div className="chatListInnerCont" onScroll={this.usersListOnScroll}>
                                {this.renderUserChatListItems()}
                                {this.state.userListfetching &&
                                    <div className="text-center">
                                        <Spinner animation="border" role="status" variant={'danger'}>
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                }
                                {!this.state.userListHasMorePages &&
                                    <div className="text-center">
                                        Koniec listy
                                    </div>
                                }
                            </div>

                        }

                    </Col>
                    <Col xs={12} md={7} className='p-2 border chatRightMainCont'>
                        {isMobile && this.state.show === 'bloggersList' &&
                            <>
                                <Row noGutters>
                                    <Col className=" px-2">
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon22">
                                                <BiSearch />
                                            </InputGroup.Text>
                                            <FormControl
                                                type="text"
                                                placeholder="Szukaj po nazwie"
                                                className="mr-sm-2"
                                                value={this.state.bloggerSearch}
                                                onChange={this.onBloggerSerachChange}
                                            />
                                            <InputGroup.Text id="basic-addon33" onClick={() => { this.clearBloggerSearch() }}>
                                                <AiOutlineClose />
                                            </InputGroup.Text>

                                        </InputGroup>
                                    </Col>
                                </Row>
                                <div className="chatListInnerCont" onScroll={this.bloggersListOnScroll} style={{ top: '50px' }}>
                                    {this.renderBloggersListItems()}
                                    {this.state.bloggersListfetching &&
                                        <div className="text-center bloggersListfetching">
                                            <Spinner animation="border" role="status" variant={'danger'}>
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    }
                                    {!this.state.blogersListHasMorePages &&
                                        <div className="text-center">
                                            Koniec listy
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {isMobile && this.state.show === 'userChatList' &&
                            <div className="chatListInnerCont" onScroll={this.usersListOnScroll}>
                                {this.renderUserChatListItems()}
                                {this.state.userListfetching &&
                                    <div className="text-center userListfetching">
                                        <Spinner animation="border" role="status" variant={'danger'}>
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                }
                                {!this.state.userListHasMorePages &&
                                    <div className="text-center">
                                        Koniec listy
                                    </div>
                                }
                            </div>
                        }
                        {this.state.show === 'chatDetails' &&
                            <>

                                <div className="chatListInnerCont mesge-area" ref={this.chatItemsWrapperRef} onScroll={this.chatDetailsOnScroll}>
                                    {this.state.chatDetailsfetching &&
                                        <div className="text-cente chatDetailsfetching">
                                            <Spinner animation="border" size={'sm'} role="status" variant={'danger'}>
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                        </div>
                                    }
                                    {!this.state.chatDetailsHasMorePages && this.state.chatDetailsData.replys.length > 0 &&
                                        <div className="text-center">
                                            Poczatek chatu
                                        </div>
                                    }
                                    {!this.state.chatDetailsHasMorePages && this.state.chatDetailsData.replys.length === 0 &&
                                        <div className="text-center">
                                            Brak wiadomości
                                        </div>
                                    }
                                <ul className="conversations ps-container ps-theme-default ps-active-y" >
                                    {this.renderUserChatItems()}
                                </ul>
                                  

                                </div>
                            </>

                        }
                    </Col>
                </Row>
                {this.renderLightbox()}
            </Col >
        )
    }

}
function mapStateToProps(state) {
    return {
        // inWatched:state.user.inWatched,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                getChatBlogers,
                getUserChatList,
                getChatDetails,
                banChatMessage,
            }, dispatch)
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Chats);