import {axiosClient, ApiUrl, HostUrl, upssssText, makeAxiosInctace} from './authActions'

export const refund=(fd, callBack) =>{
    let path=ApiUrl+"refund/"
    return dispatch =>{
      axiosClient.post(path, fd)
      .then( (response)=> {
        console.log('refund response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('dotpayRefund error', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
    }
  }
  

  export const getRefundRequests=(callBack) =>{
    let path=ApiUrl+"refundrequests/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getRefundRequests response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getRefundRequests error', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
    }
  }

  export const getRefundReasonCategories=(callBack) =>{
    let path=ApiUrl+"refundreasonscategory/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getRefundReasonCategories response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getRefundReasonCategories error', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
    }
  }

  export const getRefundReasons=(pk,callBack) =>{
    let path=ApiUrl+"refundreasons/"+pk+"/"
    return dispatch =>{
      axiosClient.get(path)
      .then( (response)=> {
        console.log('getRefundReasons response', response.data)
        callBack(response.data)
      })
      .catch( (error)=> {
        console.log('getRefundReasons error', error)
        callBack(error.response.data)
      })
      .then(()=> {
        // always executed
      });
    }
  }